import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/core/enums/loadingStatus';

// interfaces

export interface IAirExchangeItem {
  id: number;
  status?: string | null;
  room?: string | null;
  supply?: string | null;
  exhaust?: string | null;
  temperature_cold_period?: string | null;
  normative_document_number?: string | null;
  document_name?: string | null;
  clause?: string | null;
  design_note?: string | null;
  additional_info?: string | null;
  regulation_note?: string | null;
}

export interface IAirExchangeList {
  total: number | null;
  pages?: number | null;
  page: number | null;
  size: number | null;
  items: IAirExchangeItem[];
}

// payloads

export interface IAirExchangeListFetchPayload {
  search?: string;
  order_by?: number;
  status?: string;
  page: number;
  size: number;
}

// state

export interface IAirExchangeState {
  airExchangeList: IAirExchangeList | null;
  airExchangeListLock: LoadingStatus;
}

const initialState: IAirExchangeState = {
  airExchangeList: null,
  airExchangeListLock: LoadingStatus.NEVER,
};

export const airExchangeSlice = createSlice({
  name: 'airExchange',
  initialState,
  reducers: {
    // air exchange list
    fetchAirExchangeList: (
      state,
      action: PayloadAction<IAirExchangeListFetchPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setAirExchangeList: (
      state,
      action: PayloadAction<IAirExchangeList | null>
    ) => ({
      ...state,
      airExchangeList: action.payload,
    }),
    setAirExchangeListLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      airExchangeListLock: action.payload,
    }),
  },
});

export const airExchangeReducer = airExchangeSlice.reducer;
export const airExchangeActions = airExchangeSlice.actions;
