import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.auth;

const accessToken = createSelector([selector], (state) => state.accessToken);
const registerLock = createSelector([selector], (state) => state.registerLock);
const registerConfirmLock = createSelector(
  [selector],
  (state) => state.registerConfirmLock
);

//forgot
const forgotPasswordLock = createSelector(
  [selector],
  (state) => state.forgotPasswordLock
);
const forgotPasswordConfirmLock = createSelector(
  [selector],
  (state) => state.forgotPasswordConfirmLock
);

export const authSelectors = {
  accessToken,
  registerLock,
  registerConfirmLock,
  forgotPasswordLock,
  forgotPasswordConfirmLock,
};
