import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { Typography } from 'antd';
import { twMerge } from 'tailwind-merge';

import Logo from '@/images/logo.svg';

import styles from './styles.module.scss';

const { Text } = Typography;

export const PublicLayout: React.FC = () => {
  return (
    <div
      className={twMerge(
        styles.wrapper,
        'min-h-screen min-w-screen bg-neutral'
      )}
    >
      <div className={twMerge(styles.header, 'flex justify-center')}>
        <div className='flex flex-col gap-4 text-center'>
          <img src={Logo} alt='Аэропро.Эксперт' />
          <Text type='secondary'>
            Проектирование вентиляционных систем <br /> для коммерческих, жилых
            и промышленных объектов.
          </Text>
        </div>
      </div>
      <div className={styles.content}>
        <Outlet />
      </div>
      <div className={twMerge(styles.footer, ' flex justify-center')}>
        <div className='flex gap-4'>
          <Link
            to='/docs/Правила_конфиденциальности_Aeropro_Expert.pdf'
            target='_blank'
            rel='noreferrer'
          >
            <Text type='secondary' underline>
              Политика конфиденциальности
            </Text>
          </Link>
          <Link
            to='https://forms.yandex.ru/cloud/668ba1882530c256d7ff05c5/'
            target='blank'
          >
            <Text type='secondary' underline>
              Задать вопрос
            </Text>
          </Link>
        </div>
      </div>
    </div>
  );
};
