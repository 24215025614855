import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Modal, Typography } from 'antd';
import * as yup from 'yup';

import { InputForm } from '@/components/form/Input';
import { TourItem } from '@/components/ui/TourItem/TourItem';
import { LoadingStatus } from '@/core/enums/loadingStatus';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { calculationModalsSelectors } from '@/core/redux/slices/modals/smokeExtraction/calculation/selectors';
import { calculationModalsActions } from '@/core/redux/slices/modals/smokeExtraction/calculation/slice';
import { calculationSelectors } from '@/core/redux/slices/smokeExtraction/calculation/selectors';
import { calculationActions } from '@/core/redux/slices/smokeExtraction/calculation/slice';

const schema = yup.object().shape({
  calc_name: yup.string().required(),
  object_name: yup.string().required(),
  object_address: yup.string().required(),
});

interface ICalculationAddForm {
  calc_name: string;
  object_name: string;
  object_address: string;
}

const ModalContent: React.FC = () => {
  const { isRender, payload } = useAppSelector(
    calculationModalsSelectors.addCalculationModal
  );
  const { response, status } = useAppSelector(
    calculationSelectors.addCalculationLock
  );

  const navigate = useNavigate();

  const methods = useForm<ICalculationAddForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      object_name: payload?.['object_name'],
      object_address: payload?.['object_address'],
    },
  });

  const { Text, Title } = Typography;
  const dispatch = useAppDispatch();

  const handleCancel = () => {
    dispatch(calculationModalsActions.closeAddCalculationModal());
  };

  const handleCreate = (data: ICalculationAddForm) => {
    if (!payload) {
      return;
    }

    dispatch(
      calculationActions.addCalculation({
        building_object_id: payload['building_object_id'],
        ...data,
      })
    );
  };

  useEffect(() => {
    if (status !== LoadingStatus.LOADED || !response) {
      return;
    }

    navigate(`/objects/${payload?.building_object_id}/${response.id}`);
    handleCancel();
  }, [status]);

  useEffect(() => {
    return () => {
      dispatch(
        calculationActions.setAddCalculationLock({
          status: LoadingStatus.NEVER,
          response: null,
        })
      );
    };
  }, []);

  const ModalHeader: React.FC = () => {
    return (
      <div>
        <Title level={4}>Новый проект</Title>
        <Text>Номер проекта: --</Text>
      </div>
    );
  };

  const ModalFooter: React.FC = () => {
    return (
      <div className='flex gap-2'>
        <Button className='w-1/2' onClick={handleCancel}>
          Отменить
        </Button>
        <TourItem
          className='w-[292px]'
          step={5}
          placement='bottomRight'
          content={
            'Дайте название проекту, чтобы не забыть, для чего он и какие системы будет включать.'
          }
        >
          <Button
            className='w-1/2'
            type='primary'
            disabled={!methods.formState.isValid}
            onClick={() => handleCreate(methods.getValues())}
          >
            Создать проект
          </Button>
        </TourItem>
      </div>
    );
  };

  return (
    <Modal
      open={isRender}
      destroyOnClose
      onCancel={handleCancel}
      centered
      footer={null}
    >
      <ModalHeader />
      <div className='my-6'>
        <FormProvider {...methods}>
          <form>
            <div className='flex flex-col gap-4'>
              <InputForm
                name='calc_name'
                label='Наименование проекта'
                placeholder='Введите наименование проекта'
              />
              <InputForm
                name='object_name'
                label='Название объекта'
                placeholder='Введите название объекта'
                disabled
              />
              <InputForm
                name='object_address'
                label='Адрес объекта'
                placeholder='Введите адрес'
                disabled
              />
            </div>
          </form>
        </FormProvider>
      </div>
      <ModalFooter />
    </Modal>
  );
};

export const AddCalculationModal: React.FC = () => {
  const { isRender } = useAppSelector(
    calculationModalsSelectors.addCalculationModal
  );

  if (isRender) {
    return <ModalContent />;
  }

  return null;
};
