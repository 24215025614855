import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, Tabs, Typography } from 'antd';
import * as yup from 'yup';

import { InputForm } from '@/components/form/Input';
import { LoadingStatus } from '@/core/enums/loadingStatus';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { usersSelectors } from '@/core/redux/slices/users/selectors';
import { usersActions } from '@/core/redux/slices/users/slice';
import { phoneRegExp, validNameExp } from '@/core/utils/regExp';

const schema = yup.object().shape({
  name: yup.string().required().matches(validNameExp, 'Неверный формат имени'),
  phone: yup
    .string()
    .required('Это поле обязательно для заполнения')
    .trim()
    .matches(phoneRegExp, 'Неверный формат номера'),
  company_name: yup.string().required('Это поле обязательно для заполнения'),
});

interface IUserUpdateForm {
  name: string;
  phone: string;
  company_name: string;
}

export const DetailsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(usersSelectors.user);
  const updateUserLock = useAppSelector(usersSelectors.updateUserLock);

  const methods = useForm<IUserUpdateForm>({
    resolver: yupResolver(schema),
  });
  const { Text, Title } = Typography;

  const onSubmit = (data: IUserUpdateForm) => {
    dispatch(
      usersActions.updateUser({
        name: data['name'],
        phone: data['phone'],
        company_name: data['company_name'],
      })
    );
  };

  useEffect(() => {
    if (updateUserLock === LoadingStatus.LOADED) {
      dispatch(usersActions.fetchUser());
    }
  }, [updateUserLock]);

  useEffect(() => {
    return () => {
      dispatch(usersActions.setUpdateUserLock(LoadingStatus.NEVER));
    };
  }, []);

  return (
    user && (
      <Card
        style={{
          width: 368,
        }}
      >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className='flex flex-col gap-2 mb-6'>
              <Title level={4}>Почти готово</Title>
              <Text>
                Создан аккаунт для {user.email}. <br /> Введите данные
                пользователя, чтобы продолжить.
              </Text>
            </div>

            <div className='flex flex-col gap-6'>
              <div className='flex flex-col gap-2'>
                <InputForm name='name' placeholder='ФИО' size='large' />
                <InputForm name='phone' placeholder='Телефон' size='large' />
                <InputForm
                  name='company_name'
                  placeholder='Название компании'
                  size='large'
                />
              </div>
              <div>
                <Button htmlType='submit' type='primary' block className='mb-2'>
                  Войти в Аэропро.Эксперт
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
      </Card>
    )
  );
};
