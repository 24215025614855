import { Typography } from 'antd';

import { InputNumberForm } from '@/components/form/InputNumber';
import { RadioGroupForm } from '@/components/form/Radio';

interface ISprinklerFireCalculation {
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  handlePerformCalculation: () => void;
}

const { Title } = Typography;

export const SprinklerFireCalculation: React.FC<ISprinklerFireCalculation> = ({
  handleBlur,
  handlePerformCalculation,
}) => {
  const fireLoadOptions = [
    {
      label:
        'Административные помещения, учебные классы школ, ВУЗов, кабинеты поликлиник',
      value:
        'Административные помещения, учебные классы школ, ВУЗов, кабинеты поликлиник',
    },
    {
      label: 'Гардероб верхней одежды',
      value: 'Гардероб верхней одежды',
    },
    {
      label: 'Библиотеки, архивы',
      value: 'Библиотеки, архивы',
    },
    {
      label: 'Зал театра,кинотеатра, клуба, цирка и т.д.',
      value: 'Зал театра,кинотеатра, клуба, цирка и т.д.',
    },
    {
      label: 'Промтовары; текстильные изделия',
      value: 'Промтовары; текстильные изделия',
    },
  ];

  return (
    <div>
      {/* <Title level={5}>Расчет при наличии спринклерного пожаротушения</Title> */}

      <div className='flex flex-col gap-8'>
        <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
          <InputNumberForm
            name='nu'
            addonBefore={<i>η =</i>}
            label='Полнота сгорания пожарной нагрузки'
            onBlur={handleBlur}
            size='large'
          />
        </div>
        {/* <div>
          <RadioGroupForm
            name='fire_load'
            options={fireLoadOptions}
            label='Пожарная нагрузка'
            onChange={handlePerformCalculation}
            className='flex flex-col gap-2'
          />
        </div> */}
        <div className='grid grid-cols-3 gap-4 gap-y-8 items-end'>
          <InputNumberForm
            name='Qp_nsr'
            addonBefore={
              <i>
                Q<sup>p</sup>
                <sub>нср</sub> =
              </i>
            }
            addonAfter={<i>МДж/кг</i>}
            label='Низшая теплота сгорания пожарной нагрузки'
            onBlur={handleBlur}
            size='large'
          />
          <InputNumberForm
            name='psi_sr'
            addonBefore={
              <i>
                ψ<sub>ср</sub> =
              </i>
            }
            addonAfter={
              <i>
                кг/(м<sup>2</sup>с)
              </i>
            }
            label='Удельная скорость выгорания'
            onBlur={handleBlur}
            size='large'
          />
        </div>
        <div className='grid grid-cols-3 gap-4 gap-y-8 items-end'>
          <InputNumberForm
            name='a_max'
            addonBefore={
              <i>
                a<sub>макс</sub> =
              </i>
            }
            addonAfter={<i>м</i>}
            label='Максимальное расстояние между спринклерными оросителями'
            onBlur={handleBlur}
            size='large'
          />
          <InputNumberForm
            name='F0'
            addonBefore={
              <i>
                F<sub>0</sub> =
              </i>
            }
            addonAfter={
              <i>
                м<sup>2</sup>
              </i>
            }
            label='Площадь горения пожарной нагрузки'
            onBlur={handleBlur}
            disabled
            size='large'
          />
          <InputNumberForm
            name='Qf'
            addonBefore={
              <i>
                Q<sub>f</sub> =
              </i>
            }
            addonAfter={<i>кВт</i>}
            label='Мощность тепловыделения очага пожара'
            fixValue={0}
            onBlur={handleBlur}
            disabled
            size='large'
          />
        </div>
      </div>
    </div>
  );
};
