import { Breadcrumb } from 'antd';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';

export interface IBreadcrumbs {
  items: ItemType[];
}

export const Breadcrumbs: React.FC<IBreadcrumbs> = ({ items }) => {
  return (
    <div className='px-6 py-4 bg-white'>
      <Breadcrumb items={items} />
    </div>
  );
};
