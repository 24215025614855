import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { SelectProps } from 'antd';

import { InputForm } from '@/components/form/Input';
import { SelectForm } from '@/components/form/Select';
import { AirExchangeStatus } from '@/core/enums/airExchangeStatus';

export interface IAirExchangeFilterForm {
  search: string;
  status: string;
}

interface IAirExchangeFilter {
  onFilterChange: (formValues: any) => void;
}
export const AirExchangeFilter: React.FC<IAirExchangeFilter> = ({
  onFilterChange,
}) => {
  const methods = useForm<IAirExchangeFilterForm>();

  const { watch } = methods;

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      onFilterChange(value);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const airExchangeStatusOptions: SelectProps['options'] = [
    {
      value: AirExchangeStatus.NOT_DEFIENED,
      label: AirExchangeStatus.NOT_DEFIENED,
    },
    { value: AirExchangeStatus.WORK, label: AirExchangeStatus.WORK },
    { value: AirExchangeStatus.NOT_WORK, label: AirExchangeStatus.NOT_WORK },
    { value: AirExchangeStatus.DECLINED, label: AirExchangeStatus.DECLINED },
    { value: '-', label: AirExchangeStatus.EMPTY },
  ];

  return (
    <FormProvider {...methods}>
      <form>
        <div className='flex gap-4 w-full'>
          <InputForm
            name='search'
            placeholder='Найти помещение или документ'
            size='large'
            className='flex-grow'
          />
          <SelectForm
            name='status'
            placeholder='Все статусы'
            options={airExchangeStatusOptions}
            className='w-[440px]'
            size='large'
            allowClear
          />
        </div>
      </form>
    </FormProvider>
  );
};
