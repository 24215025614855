import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.buildingObject;

//building object
const buildingObject = createSelector(
  [selector],
  (state) => state.buildingObject
);
const buildingObjectItems = createSelector(
  [selector],
  (state) => state.buildingObject?.items
);
const buildingObjectTotal = createSelector(
  [selector],
  (state) => state.buildingObject?.total
);
const buildingObjectLock = createSelector(
  [selector],
  (state) => state.buildingObjectLock
);

//current
const currentBuildingObject = createSelector(
  [selector],
  (state) => state.currentBuildingObject
);
//add
const addBuildingObjectLock = createSelector(
  [selector],
  (state) => state.addBuildingObjectLock
);

export const buildingObjectSelectors = {
  buildingObject,
  buildingObjectLock,
  buildingObjectItems,
  buildingObjectTotal,
  currentBuildingObject,
  addBuildingObjectLock
};
