export const AddIcon: React.FC = () => {
  return (
    <svg
      width='14'
      height='15'
      viewBox='0 0 14 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.46838 1.87598H6.53088C6.44755 1.87598 6.40588 1.91764 6.40588 2.00098V6.90723H1.75C1.66667 6.90723 1.625 6.94889 1.625 7.03223V7.96973C1.625 8.05306 1.66667 8.09473 1.75 8.09473H6.40588V13.001C6.40588 13.0843 6.44755 13.126 6.53088 13.126H7.46838C7.55172 13.126 7.59338 13.0843 7.59338 13.001V8.09473H12.25C12.3333 8.09473 12.375 8.05306 12.375 7.96973V7.03223C12.375 6.94889 12.3333 6.90723 12.25 6.90723H7.59338V2.00098C7.59338 1.91764 7.55172 1.87598 7.46838 1.87598Z'
        fill='black'
        fill-opacity='0.85'
      />
    </svg>
  );
};
