import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/core/enums/loadingStatus';

export interface ISystemPerfomCalculationPayload {
  calc_id: number;
  data: any;
}

export interface ICalculationState {
  perfomCalculationLock: LoadingStatus;
}

const initialState: ICalculationState = {
  perfomCalculationLock: LoadingStatus.NEVER,
};

export const systemCalculations = createSlice({
  name: 'systemCalculations',
  initialState,
  reducers: {
    performFacadeSmokeHatches: (
      state,
      action: PayloadAction<ISystemPerfomCalculationPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    performRoofSmokeHatches: (
      state,
      action: PayloadAction<ISystemPerfomCalculationPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    performCompensatingAirSupply: (
      state,
      action: PayloadAction<ISystemPerfomCalculationPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    performAirCurtains: (
      state,
      action: PayloadAction<ISystemPerfomCalculationPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    performAirLocks: (
      state,
      action: PayloadAction<ISystemPerfomCalculationPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    performFireProofZones: (
      state,
      action: PayloadAction<ISystemPerfomCalculationPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    performSmokeFreeStairwells: (
      state,
      action: PayloadAction<ISystemPerfomCalculationPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    performRemovingFromBurning: (
      state,
      action: PayloadAction<ISystemPerfomCalculationPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    performRemovingFromAdjacentToBurning: (
      state,
      action: PayloadAction<ISystemPerfomCalculationPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    performElevatorShafts: (
      state,
      action: PayloadAction<ISystemPerfomCalculationPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    performAerodynamics: (
      state,
      action: PayloadAction<ISystemPerfomCalculationPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setPerformCalculationsLock: (
      state,
      action: PayloadAction<LoadingStatus>
    ) => ({
      ...state,
      perfomCalculationLock: action.payload,
    }),
  },
});

export const systemCalculationsReducer = systemCalculations.reducer;
export const systemCalculationsActions = systemCalculations.actions;
