import { Navigate, Route, Routes } from 'react-router-dom';

import ProfileRoute from './ProfileRoute';

const ObjectsRootRoute: React.FC = () => {
  return (
    <Routes>
      <Route path='/' element={<ProfileRoute />} />
      <Route path={'*'} element={<Navigate to={'/'} />} />
    </Routes>
  );
};

export default ObjectsRootRoute;
