import { Controller, useFormContext } from 'react-hook-form';
import { Input, InputProps, Typography } from 'antd';

interface InputForm extends InputProps {
  label?: string;
  name: string;
  defaultValue?: string;
  note?: string;
}

export const PasswordForm: React.FC<InputForm> = (props) => {
  const { Text } = Typography;

  const { name, defaultValue, label, note } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = errors[name];

  return (
    <div className='flex flex-col gap-1 relative'>
      {label && (
        <label htmlFor={name} className='text-secondary'>
          {label}
        </label>
      )}
      <Controller
        render={({ field }) => (
          <Input.Password {...props} {...field} status={error && 'error'} />
        )}
        control={control}
        name={name}
        defaultValue={defaultValue}
      />
      {note && (
        <Typography.Text
          type='secondary'
          className='text-xs'
        >
          {note}
        </Typography.Text>
      )}
      {error && (
        <Text className='block' type='danger'>
          {String(error.message)}
        </Text>
      )}
    </div>
  );
};
