import { FocusEvent, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import _ from 'lodash';

import { InputNumberForm } from '@/components/form/InputNumber';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { systemCalculationsActions } from '@/core/redux/slices/smokeExtraction/system/calculations/slice';
import { systemSelectors } from '@/core/redux/slices/smokeExtraction/system/selectors';

import DynamicTable from './DynamicFireLoadTable/DynamicTable';
import { DynamicRoomOpeningTable } from './DynamicRoomOpeningTable/DynamicRoomOpeningTable';
import { RoomOpening } from './sections/RoomOpening/RoomOpening';
import { RoomParametrs } from './sections/RoomParameters/RoomParametrs';

export interface IRemoveFromAdjacentToBurningView {
  systemID: number;
}

export const RemoveFromAdjacentToBurningView: React.FC<
  IRemoveFromAdjacentToBurningView
> = ({ systemID }) => {
  const methods = useForm({
    defaultValues: {
      fire_load: [
        {
          Mi: '1000',
          mi: '0',
          Qp_ni: '13.8',
        },
      ],
      Ff: 20,
      hn: 2.7,
      V: null,
      Fw: null,
      room_opening: [
        {
          ai: 0.9,
          hi: 2,
          Ai: 0,
        },
      ],
      A0: null,
      Qpnd: 13.8,
      gk: null,
      Qpns: null,
      V0: null,
      P: null,
      gkkp: null,
      fire_type: 'ПРВ',
      g0: null,
      tr: 25,
      Tr: null,
      T0max: null,
      T0: null,
      H: 2.7,
      Ac: 22.5,
      lc: 15,
      hsmH: 0.55,
      hsm: null,
      trk: 26,
      Trk: null,
      Tsm: null,
      tsm: null,
      building_type: 'Жилое',
      ksm: null,
      ad: 0.9,
      Hd: 2,
      Ad: null,
      Gsm: null,
      psm: null,
      Lsm: null,
    },
  });
  const systemState = useAppSelector(systemSelectors.systemState);

  const dispatch = useAppDispatch();

  const handlePerformCalculation = () => {
    const data = methods.getValues();

    const cloneData = _.cloneDeep(data);

    dispatch(
      systemCalculationsActions.performRemovingFromAdjacentToBurning({
        calc_id: systemID,
        data: cloneData,
      })
    );
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (!systemState || systemState[name] !== value) {
      handlePerformCalculation();
    }
  };

  useEffect(() => {
    systemState && methods.reset(systemState);
  }, [systemState]);

  return (
    <FormProvider {...methods}>
      <form>
        <div className='flex flex-col gap-8'>
          <DynamicTable
            handleChange={handlePerformCalculation}
            handleBlur={handleBlur}
          />
          <RoomParametrs handleBlur={handleBlur} />
          <RoomOpening
            handleBlur={handleBlur}
            handleChange={handlePerformCalculation}
          />
        </div>
      </form>
    </FormProvider>
  );
};
