import { PayloadAction } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { NormalizeOAS, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { LoadingStatus } from '@/core/enums/loadingStatus';
import type oas from '@/services/rest/base/openapi';

import { usersActions } from '../users/slice';

import {
  authActions,
  IChangePasswordPayload,
  IForgotPasswordConfirmPayload,
  IForgotPasswordPayload,
  ILoginPayload,
  IRegisterConfirmPayload,
  IRegisterPayload,
} from './slice';

type RegisterRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/auth/register',
  'post'
>;
type RegisterConfirmRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/auth/register_confirm',
  'post'
>;
type LoginRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/auth/login',
  'post'
>;
type LogoutRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/auth/logout',
  'post'
>;
type ChangePasswordRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/auth/change_password',
  'post'
>;
type ForgotPasswordRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/auth/forgot_password',
  'post'
>;
type ForgotPasswordConfirmRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/auth/forgot_password_confirm',
  'post'
>;

function* register(
  action: PayloadAction<IRegisterPayload>
): Generator<any, void, any> {
  const { email, password } = action.payload;

  const request: RegisterRequest = {
    json: {
      email: email,
      password: password,
    },
  };

  yield put(authActions.setRegisterLock(LoadingStatus.LOADING));

  try {
    yield call(restCall, '/auth/register', 'post', request);

    yield put(
      usersActions.setUser({
        uuid: 'new',
        email: email,
        password: password,
      })
    );

    yield put(authActions.setRegisterLock(LoadingStatus.LOADED));
  } catch (error: any) {
    yield put(authActions.setRegisterLock(LoadingStatus.ERROR));

    notification.error({
      message: 'Ошибка',
      description: error.message,
    });
  }
}

function* registerConfirm(
  action: PayloadAction<IRegisterConfirmPayload>
): Generator<any, void, any> {
  const { email, code } = action.payload;

  const request: RegisterConfirmRequest = {
    json: {
      email: email,
      code: code,
    },
  };

  yield put(authActions.setRegisterConfirmLock(LoadingStatus.LOADING));

  try {
    const response = yield call(
      restCall,
      '/auth/register_confirm',
      'post',
      request
    );

    const accessToken = response.access_token;

    localStorage.setItem('accessToken', accessToken);
    yield put(authActions.setAccessToken(accessToken));

    yield put(authActions.setRegisterConfirmLock(LoadingStatus.LOADED));
  } catch (error: any) {
    console.error('Error on register', error);
    yield put(authActions.setRegisterConfirmLock(LoadingStatus.ERROR));

    notification.error({
      message: 'Ошибка',
      description: error.message,
    });
  }
}

function* login(
  action: PayloadAction<ILoginPayload>
): Generator<any, void, any> {
  const { email, password } = action.payload;

  const request: LoginRequest = {
    json: {
      email: email,
      password: password,
    },
  };

  try {
    const response = yield call(restCall, '/auth/login', 'post', request);

    const accessToken = response.access_token;

    localStorage.setItem('accessToken', accessToken);
    yield put(authActions.setAccessToken(accessToken));
  } catch (error: any) {
    console.log('Error on login', error);

    notification.error({
      message: 'Ошибка',
      description: error.message,
    });
  }
}

function* changePassword(
  action: PayloadAction<IChangePasswordPayload>
): Generator<any, void, any> {
  const { currentPassword, newPassword } = action.payload;

  const request: ChangePasswordRequest = {
    json: {
      current_password: currentPassword,
      new_password: newPassword,
    },
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/auth/change_password',
      'post',
      request
    );

    const accessToken = response.access_token;

    localStorage.setItem('accessToken', accessToken);
    yield put(authActions.setAccessToken(accessToken));
  } catch (error: any) {
    console.log('Error on change password', error);

    notification.error({
      message: 'Ошибка',
      description: error.message,
    });
  }
}

function* forgotPassword(
  action: PayloadAction<IForgotPasswordPayload>
): Generator<any, void, any> {
  const { email } = action.payload;

  yield put(authActions.setForgotPasswordLock(LoadingStatus.LOADING));

  const request: ForgotPasswordRequest = {
    json: {
      email: email,
    },
    ...authAdd(),
  };

  try {
    yield call(restCall, '/auth/forgot_password', 'post', request);

    yield put(
      usersActions.setUser({
        uuid: 'reset',
        email: email,
      })
    );

    yield put(authActions.setForgotPasswordLock(LoadingStatus.LOADED));
  } catch (error: any) {
    console.log('Error on reset password', error);
    yield put(authActions.setForgotPasswordLock(LoadingStatus.ERROR));

    notification.error({
      message: 'Ошибка',
      description: error.message,
    });
  }
}

function* forgotPasswordConfirm(
  action: PayloadAction<IForgotPasswordConfirmPayload>
): Generator<any, void, any> {
  const { email, code, password } = action.payload;

  yield put(authActions.setForgotPasswordConfirmLock(LoadingStatus.LOADING));

  const request: ForgotPasswordConfirmRequest = {
    json: {
      email: email,
      code: code,
      password,
    },
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/auth/forgot_password_confirm',
      'post',
      request
    );

    const accessToken = response?.access_token;

    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      yield put(authActions.setAccessToken(accessToken));

      yield put(authActions.setForgotPasswordConfirmLock(LoadingStatus.LOADED));
    }
  } catch (error: any) {
    console.log('Error on reset password', error);
    yield put(authActions.setForgotPasswordConfirmLock(LoadingStatus.ERROR));

    notification.error({
      message: 'Ошибка',
      description: error.message,
    });
  }
}

function* logout(): Generator<any, void, any> {
  try {
    yield call(restCall, '/auth/logout', 'post');

    localStorage.removeItem('accessToken');
    yield put(authActions.setAccessToken(null));
    yield put(usersActions.setUser(null));
  } catch (error: any) {
    console.error('Error on logout', error);

    notification.error({
      message: 'Ошибка',
      description: error.message,
    });
  }
}

export const authSagas = [
  takeLatest(authActions.register, register),
  takeLatest(authActions.registerConfirm, registerConfirm),
  takeLatest(authActions.login, login),
  takeLatest(authActions.logout, logout),
  takeLatest(authActions.changePassword, changePassword),
  takeLatest(authActions.forgotPassword, forgotPassword),
  takeLatest(authActions.forgotPasswordConfirm, forgotPasswordConfirm),
];
