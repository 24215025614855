import {
  Badge,
  Card,
  Collapse,
  CollapseProps,
  Divider,
  Typography,
} from 'antd';
import { twMerge } from 'tailwind-merge';

import { IAirExchangeItem } from '@/core/redux/slices/airExchange/slice';

const { Text, Title, Paragraph } = Typography;

type StatusTypes =
  | 'Не определен'
  | 'Действует'
  | 'Не действует'
  | 'Действует. Отменен в части'
  | '-';

interface IExchangeCard {
  title: string;
  content: string;
}

export interface IStatus {
  value: StatusTypes;
}

// Map status values to their respective colors
const statusColors = {
  'Не определен': {
    divColor: 'bg-calendula-gold',
    badgeColor: '#FAAD14',
  },
  Действует: {
    divColor: 'bg-polar-green',
    badgeColor: '#52C41A',
  },
  'Действует. Отменен в части': {
    divColor: 'bg-polar-green',
    badgeColor: '#52C41A',
  },
  'Не действует': {
    divColor: 'bg-dust-red',
    badgeColor: '#FF4D4F',
  },
  '-': {
    divColor: 'bg-[#DBE2E9]',
    badgeColor: '#CCC',
  },
};

const Status: React.FC<IStatus> = ({ value }) => {
  const { divColor, badgeColor } = statusColors[value];

  return (
    <div
      className={twMerge(
        'flex gap-2 items-center rounded-xl px-2 h-[22px] min-w-fit',
        divColor
      )}
    >
      <Badge color={badgeColor} />
      <Text>{value === '-' ? 'Без статуса' : value}</Text>
    </div>
  );
};

const ExchangeCard: React.FC<IExchangeCard> = ({ title, content }) => {
  return (
    <Card
      title={
        <Text type='secondary' className='font-normal'>
          {title}
        </Text>
      }
      className='w-1/3'
    >
      <Text>{content}</Text>
    </Card>
  );
};

export const AirExchangeItem: React.FC<IAirExchangeItem> = ({
  status,
  document_name,
  normative_document_number,
  clause,
  room,
  supply,
  exhaust,
  temperature_cold_period,
  design_note,
  regulation_note,
}) => {
  const { Panel } = Collapse;

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Для проектирования',
      children: design_note,
    },
    {
      key: '2',
      label: 'Из нормативов',
      children: regulation_note,
    },
  ];

  return (
    <div className='px-2'>
      <div className='flex flex-col gap-4'>
        <div className='flex gap-4 justify-between'>
          <Text type='secondary' className='block grow'>
            {document_name}
          </Text>
          <div className='text-right'>
            <Text className='block' type='secondary'>
              {normative_document_number}
            </Text>
            <Text className='block' type='secondary'>
              {clause}
            </Text>
          </div>
        </div>
        <div className='flex justify-between items-center'>
          <Title level={4} className='!m-0'>
            {room}
          </Title>
          {status && <Status value={status as StatusTypes} />}
        </div>
        <div className='flex gap-2'>
          {supply && <ExchangeCard title='Приток' content={supply} />}
          {exhaust && <ExchangeCard title='Вытяжка' content={exhaust} />}
          {temperature_cold_period && (
            <ExchangeCard
              title='Температура в холодный период года, °С'
              content={temperature_cold_period}
            />
          )}
        </div>
        {(design_note || regulation_note) && (
          <div>
            <Title level={5} className='block !mb-3'>
              Примечания
            </Title>
            <Collapse
              destroyInactivePanel
              defaultActiveKey={['1', '2']}
              expandIconPosition='end'
            >
              {items.map((item, index) => {
                return item.children ? (
                  <Panel key={index} header={item.label}>
                    <Paragraph className='!m-0'>{item.children}</Paragraph>
                  </Panel>
                ) : null;
              })}
            </Collapse>
          </div>
        )}
      </div>
      <Divider className='!mt-8' />
    </div>
  );
};
