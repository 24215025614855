import { Link } from 'react-router-dom';
import { Typography } from 'antd';

const { Text } = Typography;

export const Footer: React.FC = () => {
  return (
    <div className='p-6 flex justify-end gap-4'>
      <Link to='/docs/Пользовательское_соглашение_Aeropro_Expert.pdf' target='_blank' rel='noreferrer'>
        <Text type='secondary' underline>
          Пользовательское соглашение
        </Text>
      </Link>
      <Link to='/docs/Правила_конфиденциальности_Aeropro_Expert.pdf' target='_blank' rel='noreferrer'>
        <Text type='secondary' underline>
          Политика конфиденциальности
        </Text>
      </Link>
    </div>
  );
};
