import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { buildingObjectSelectors } from '@/core/redux/slices/smokeExtraction/buildingObject/selectors';
import { buildingObjectActions } from '@/core/redux/slices/smokeExtraction/buildingObject/slice';

interface IUseCurrentBuildingObject {
  buildingObjectID: number;
}

export const useCurrentBuildingObject = ({
  buildingObjectID,
}: IUseCurrentBuildingObject) => {
  const currentBuildingObject = useAppSelector(
    buildingObjectSelectors.currentBuildingObject
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!buildingObjectID) {
      return;
    }

    dispatch(
      buildingObjectActions.fetchCurrentBuildingObject({
        id: buildingObjectID,
      })
    );
  }, [buildingObjectID]);

  useEffect(() => {
    return () => {
      buildingObjectActions.setCurrentBuildingObject(null);
    };
  }, []);
  return currentBuildingObject;
};
