import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, Tabs } from 'antd';
import * as yup from 'yup';

import { InputForm } from '@/components/form/Input';
import { PasswordForm } from '@/components/form/Password';
import { useAppDispatch } from '@/core/redux/hooks';
import { authActions } from '@/core/redux/slices/auth/slice';

const schema = yup.object().shape({
  email: yup.string().email('Неправильный формат почты').required('Поле обязательно для заполнения'),
  password: yup.string().required('Поле обязательно для заполнения'),
});

interface ILoginForm {
  email: string;
  password: string;
}

export const LoginPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const methods = useForm<ILoginForm>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: ILoginForm) => {
    dispatch(
      authActions.login({
        email: data.email,
        password: data.password,
      })
    );
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className='flex flex-col gap-6'>
          <div className='flex flex-col gap-2'>
            <InputForm name='email' placeholder='Ваш e-mail' size='large' />
            <PasswordForm name='password' placeholder='Пароль' size='large' />
          </div>
          <Button htmlType='submit' type='primary' block>
            Войти
          </Button>
          <div className='flex gap-2'>
            <Button type='link' onClick={() => navigate('/auth/signup')} className='w-full'>
              Регистрация
            </Button>
            <Button type='link'  onClick={() => navigate('/auth/reset')} className='w-full'>Забыли пароль?</Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
