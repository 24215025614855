import { FocusEvent, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { SelectProps, Typography } from 'antd';

import { InputNumberForm } from '@/components/form/InputNumber';
import { DebounceSelect } from '@/components/ui/DebounceSelect/DebounceSelect';
import { LoadingStatus } from '@/core/enums/loadingStatus';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { systemCalculationsActions } from '@/core/redux/slices/smokeExtraction/system/calculations/slice';
import { systemParamsSelectors } from '@/core/redux/slices/smokeExtraction/system/params/selectors';
import { systemParamsActions } from '@/core/redux/slices/smokeExtraction/system/params/slice';
import { systemSelectors } from '@/core/redux/slices/smokeExtraction/system/selectors';

const { Title } = Typography;

export interface ICompensatingAirSupplyView {
  systemID: number;
}

export const CompensatingAirSupplyView: React.FC<
  ICompensatingAirSupplyView
> = ({ systemID }) => {
  const cityParams = useAppSelector(systemParamsSelectors.cityParams);
  const cityParamsLock = useAppSelector(systemParamsSelectors.cityParamsLock);

  const systemState = useAppSelector(systemSelectors.systemState);
  const dispatch = useAppDispatch();

  const methods = useForm<any>({
    defaultValues: {
      city_id: 1,
      city: 'Москва',
      ta: 0,
      pa: 0,
      Gsm: null,
      n: null,
      Ga: null,
      La: null,
    },
  });

  const cityParamsOptions: SelectProps['options'] = useMemo(() => {
    if (!cityParams) {
      return [];
    }

    return cityParams.map((item) => ({
      value: item.id,
      label: item.city,
    }));
  }, [cityParams]);

  const handlePerformCalculation = () => {
    const data = methods.getValues();

    dispatch(
      systemCalculationsActions.performCompensatingAirSupply({
        calc_id: systemID,
        data: data,
      })
    );
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (!systemState || systemState[name] !== value) {
      handlePerformCalculation();
    }
  };
  const onSelectedCityChange = (id: string) => {
    if (!cityParams) {
      return;
    }

    const selectedCityID = Number(id);

    const selectedCity = cityParams.find((item) => item.id === selectedCityID);

    if (selectedCity) {
      methods.setValue('city', String(selectedCity.city));
      methods.setValue('ta', Number(selectedCity.cold_temp));
    }

    handlePerformCalculation();
  };

  useEffect(() => {
    systemState && methods.reset(systemState);
  }, [systemState]);

  useEffect(() => {
    if (!systemState) {
      dispatch(
        systemParamsActions.fetchCityParams({
          page: 1,
          size: 10,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (cityParamsLock === LoadingStatus.LOADED) {
      !systemState && cityParams && onSelectedCityChange('1');
    }
  }, [cityParams]);

  return (
    <FormProvider {...methods}>
      <form>
        <div className='py-4 px-2'>
          <Title level={5} className='mb-4'>
            Город
          </Title>
          <DebounceSelect
            size='large'
            showSearch
            placeholder='Выберите город'
            defaultValue={{
              value: methods.getValues('city_id'),
              label: methods.getValues('city'),
            }}
            value={{
              value: methods.getValues('city_id'),
              label: methods.getValues('city'),
            }}
            style={{ width: '100%' }}
            onChange={(newValue: any) => {
              methods.setValue('city_id', newValue.key);
              onSelectedCityChange(newValue.key);
            }}
            options={cityParamsOptions}
            fetchOptions={(search: string) => {
              dispatch(
                systemParamsActions.fetchCityParams({
                  page: 1,
                  size: 10,
                  search: search,
                })
              );
            }}
            loading={cityParamsLock === LoadingStatus.LOADING}
          />
        </div>
        <div className='py-4 px-2'>
          <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
            <InputNumberForm
              name='ta'
              addonBefore={
                <i>
                  t<sub>a</sub> =
                </i>
              }
              addonAfter={'°C'}
              label='Температура наружного воздуха'
              disabled
              onBlur={handleBlur}
              size='large'
            />
            <InputNumberForm
              name='pa'
              addonBefore={
                <i>
                  ρ<sub>a</sub> =
                </i>
              }
              addonAfter={
                <i>
                  кг/м<sup>3</sup>
                </i>
              }
              label='Плотность наружного воздуха'
              disabled
              onBlur={handleBlur}
              size='large'
            />

            <InputNumberForm
              name='Gsm'
              addonBefore={
                <i>
                  G<sub>sm</sub> =
                </i>
              }
              addonAfter={<i>кг/с</i>}
              label='Массовый расход удаляемых непосредственно из помещения (коридора) продуктов горения'
              onBlur={handleBlur}
              size='large'
            />
            <InputNumberForm
              name='n'
              addonBefore={<i>n =</i>}
              note='Коэффициент дисбаланса (-0,3 ≤ n ≤ 0)'
              onBlur={handleBlur}
              stringMode
              size='large'
            />

            <InputNumberForm
              name='Ga'
              addonBefore={
                <i>
                  G<sub>a</sub> =
                </i>
              }
              addonAfter={<i>кг/с</i>}
              label='Массовый расход компенсирующей подачи воздуха, подаваемого непосредственно в защищаемое помещение'
              disabled
              onBlur={handleBlur}
              size='large'
              fixValue={2}
            />
            <InputNumberForm
              name='La'
              addonBefore={
                <i>
                  L<sub>a</sub> =
                </i>
              }
              addonAfter={
                <i>
                  м<sup>3</sup>/ч
                </i>
              }
              label='Объемный расход компенсирующей подачи воздуха,подаваемого непосредственно в защищаемое помещение'
              disabled
              onBlur={handleBlur}
              size='large'
            />
          </div>
        </div>
      </form>
      <Typography>
        При приемосдаточных и периодических испытаниях, а также на начальной
        стадии пожара необходимо соблюсти баланс между расходом удаляемого дыма
        и расходом подаваемого наружного воздуха.
      </Typography>
    </FormProvider>
  );
};
