import { Button, Modal, Table, TableColumnsType, Typography } from 'antd';

import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { systemModalsSelectors } from '@/core/redux/slices/modals/smokeExtraction/system/selectors';
import {
  IElevartorShaftResult,
  IResult,
  systemModalsActions,
} from '@/core/redux/slices/modals/smokeExtraction/system/slice';

const ModalContent: React.FC = () => {
  const { Text, Title } = Typography;
  const dispatch = useAppDispatch();

  const { isRender, payload } = useAppSelector(
    systemModalsSelectors.elevatorShaftsResultModal
  );

  const handleCancel = () => {
    dispatch(systemModalsActions.closeElevatorShaftsResultModal());
  };

  const columns: TableColumnsType<IElevartorShaftResult> = [
    {
      title: 'Участок',
      dataIndex: 'i',
    },
    {
      title: <i>h, м</i>,
      dataIndex: 'h',
    },
    {
      title: <i>Pl, Па</i>,
      dataIndex: 'Pl',
      render: (text) => Number(text).toFixed(1),
    },
    {
      title: <i>ΔGl, кг/с</i>,
      dataIndex: 'dGl',
      render: (text) => Number(text).toFixed(2),
    },
  ];

  return (
    <Modal
      open={isRender}
      destroyOnClose
      onCancel={handleCancel}
      centered
      title='Результирующая таблица'
      footer={null}
      width={751}
    >
      {payload && (
        <Table
          columns={columns}
          dataSource={payload.resultTableData}
          pagination={false}
        />
      )}
    </Modal>
  );
};

export const ElevatorShaftsResultModal: React.FC = () => {
  const { isRender } = useAppSelector(
    systemModalsSelectors.elevatorShaftsResultModal
  );

  if (isRender) {
    return <ModalContent />;
  }

  return null;
};
