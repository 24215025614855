import { PayloadAction } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import type oas from '@/services/rest/base/openapi';

import { systemSelectors } from '../selectors';
import { systemActions } from '../slice';

import {
  ISystemPerfomCalculationPayload,
  systemCalculationsActions,
} from './slice';

type FacadeSmokeHatchesRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/facade_smoke_hatches',
  'post'
>;

type RoofSmokeHatchesRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/roof_smoke_hatches',
  'post'
>;

type CompensatingAirSupplyRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/compensating_air_supply',
  'post'
>;

type AirCurtainsRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/air_curtains',
  'post'
>;

type AirLocksRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/air_locks',
  'post'
>;

type FireProofRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/fireproof_zones',
  'post'
>;

type SmokeFreeStairwellsRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/smoke_free_stairwells',
  'post'
>;

type RemoveFromBurning = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/removing_from_burning',
  'post'
>;

type RemoveFromAdjacentToBurning = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/removing_from_adjacent_to_burning',
  'post'
>;

type ElevatorShafts = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/elevator_shafts',
  'post'
>;

type Aerodynamics = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/aerodynamics',
  'post'
>;

// helpers

const showNotificationError = () => {
  notification.error({
    message: 'Ошибка в расчете',
    description: 'Укажите другие данные',
  });
};

// Facade Smoke Hatches

function* performFacadeSmokeHatches(
  action: PayloadAction<ISystemPerfomCalculationPayload>
): Generator<any, void, any> {
  const { calc_id, data } = action.payload;

  const system = yield select(systemSelectors.system);

  const request: FacadeSmokeHatchesRequest = {
    json: {
      calc_id: calc_id,
      state: data,
    },
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/smoke_extraction/facade_smoke_hatches',
      'post',
      request
    );

    if (!response.state) {
      throw new Error();
    }

    yield put(
      systemActions.setSystem({
        ...system,
        state: response.state,
      })
    );

    yield put(systemActions.setIsReportReady(response.state.finished));
  } catch (error) {
    console.log('Error on perform calculation', error);
    showNotificationError();
  }
}

// Roof Smoke Hatches

function* performRoofSmokeHatches(
  action: PayloadAction<ISystemPerfomCalculationPayload>
): Generator<any, void, any> {
  const { calc_id, data } = action.payload;

  const system = yield select(systemSelectors.system);

  const request: RoofSmokeHatchesRequest = {
    json: {
      calc_id: calc_id,
      state: data,
    },
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/smoke_extraction/roof_smoke_hatches',
      'post',
      request
    );

    if (!response.state) {
      throw new Error();
    }

    yield put(
      systemActions.setSystem({
        ...system,
        state: response.state,
      })
    );

    yield put(systemActions.setIsReportReady(response.state.finished));
  } catch (error) {
    console.log('Error on perform calculation', error);
    showNotificationError();
  }
}

// Compensating Air Supply

function* performCompensatingAirSupply(
  action: PayloadAction<ISystemPerfomCalculationPayload>
): Generator<any, void, any> {
  const { calc_id, data } = action.payload;

  const system = yield select(systemSelectors.system);

  const request: CompensatingAirSupplyRequest = {
    json: {
      calc_id: calc_id,
      state: data,
    },
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/smoke_extraction/compensating_air_supply',
      'post',
      request
    );

    if (!response.state) {
      throw new Error();
    }

    yield put(
      systemActions.setSystem({
        ...system,
        state: response.state,
      })
    );

    yield put(systemActions.setIsReportReady(response.state.finished));
  } catch (error) {
    console.log('Error on perform calculation', error);
    showNotificationError();
  }
}

// Air Curtains

function* performAirCurtains(
  action: PayloadAction<ISystemPerfomCalculationPayload>
): Generator<any, void, any> {
  const { calc_id, data } = action.payload;

  const system = yield select(systemSelectors.system);

  const request: AirCurtainsRequest = {
    json: {
      calc_id: calc_id,
      state: data,
    },
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/smoke_extraction/air_curtains',
      'post',
      request
    );

    if (!response.state) {
      throw new Error();
    }

    yield put(
      systemActions.setSystem({
        ...system,
        state: response.state,
      })
    );

    yield put(systemActions.setIsReportReady(response.state.finished));
  } catch (error) {
    console.log('Error on perform calculation', error);
    showNotificationError();
  }
}

// Air Locks

function* performAirLocks(
  action: PayloadAction<ISystemPerfomCalculationPayload>
): Generator<any, void, any> {
  const { calc_id, data } = action.payload;

  const system = yield select(systemSelectors.system);

  const request: AirLocksRequest = {
    json: {
      calc_id: calc_id,
      state: data,
    },
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/smoke_extraction/air_locks',
      'post',
      request
    );

    if (!response.state) {
      throw new Error();
    }

    yield put(
      systemActions.setSystem({
        ...system,
        state: response.state,
      })
    );

    yield put(systemActions.setIsReportReady(response.state.finished));
  } catch (error) {
    console.log('Error on perform calculation', error);
    showNotificationError();
  }
}

// Fire Proof Zones

function* performFireProofZones(
  action: PayloadAction<ISystemPerfomCalculationPayload>
): Generator<any, void, any> {
  const { calc_id, data } = action.payload;

  const system = yield select(systemSelectors.system);

  const request: FireProofRequest = {
    json: {
      calc_id: calc_id,
      state: data,
    },
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/smoke_extraction/fireproof_zones',
      'post',
      request
    );

    if (!response.state) {
      throw new Error();
    }

    yield put(
      systemActions.setSystem({
        ...system,
        state: response.state,
      })
    );

    yield put(systemActions.setIsReportReady(response.state.finished));
  } catch (error) {
    console.log('Error on perform calculation', error);
    showNotificationError();
  }
}

// Smoke Free Stairwells

function* performSmokeFreeStairwells(
  action: PayloadAction<ISystemPerfomCalculationPayload>
): Generator<any, void, any> {
  const { calc_id, data } = action.payload;

  const system = yield select(systemSelectors.system);

  const request: SmokeFreeStairwellsRequest = {
    json: {
      calc_id: calc_id,
      state: data,
    },
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/smoke_extraction/smoke_free_stairwells',
      'post',
      request
    );

    if (!response.state) {
      throw new Error();
    }

    yield put(
      systemActions.setSystem({
        ...system,
        state: response.state,
      })
    );

    yield put(systemActions.setIsReportReady(response.state.finished));
  } catch (error) {
    console.log('Error on perform calculation', error);
    showNotificationError();
  }
}

// Remove from burning

function* performRemovingFromBurning(
  action: PayloadAction<ISystemPerfomCalculationPayload>
): Generator<any, void, any> {
  const { calc_id, data } = action.payload;

  const system = yield select(systemSelectors.system);

  const request: RemoveFromBurning = {
    json: {
      calc_id: calc_id,
      state: data,
    },
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/smoke_extraction/removing_from_burning',
      'post',
      request
    );

    if (!response.state) {
      throw new Error();
    }

    yield put(
      systemActions.setSystem({
        ...system,
        state: response.state,
      })
    );

    yield put(systemActions.setIsReportReady(response.state.finished));
  } catch (error) {
    console.log('Error on perform calculation', error);
    showNotificationError();
  }
}

// Remove from adjacent to burning

function* performRemovingFromAdjacentToBurning(
  action: PayloadAction<ISystemPerfomCalculationPayload>
): Generator<any, void, any> {
  const { calc_id, data } = action.payload;

  const system = yield select(systemSelectors.system);

  const request: RemoveFromAdjacentToBurning = {
    json: {
      calc_id: calc_id,
      state: data,
    },
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/smoke_extraction/removing_from_adjacent_to_burning',
      'post',
      request
    );

    if (!response.state) {
      throw new Error();
    }

    yield put(
      systemActions.setSystem({
        ...system,
        state: response.state,
      })
    );

    yield put(systemActions.setIsReportReady(response.state.finished));
  } catch (error) {
    console.log('Error on perform calculation', error);
    showNotificationError();
  }
}

// Aerodynamics

function* performElevatorShafts(
  action: PayloadAction<ISystemPerfomCalculationPayload>
): Generator<any, void, any> {
  const { calc_id, data } = action.payload;

  const system = yield select(systemSelectors.system);

  const request: Aerodynamics = {
    json: {
      calc_id: calc_id,
      state: data,
    },
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/smoke_extraction/elevator_shafts',
      'post',
      request
    );

    if (!response.state) {
      throw new Error();
    }

    yield put(
      systemActions.setSystem({
        ...system,
        state: response.state,
      })
    );

    yield put(systemActions.setIsReportReady(response.state.finished));
  } catch (error) {
    console.log('Error on perform calculation', error);
    showNotificationError();
  }
}

function* performAerodynamics(
  action: PayloadAction<ISystemPerfomCalculationPayload>
): Generator<any, void, any> {
  const { calc_id, data } = action.payload;

  const system = yield select(systemSelectors.system);

  const request: Aerodynamics = {
    json: {
      calc_id: calc_id,
      state: data,
    },
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/smoke_extraction/aerodynamics',
      'post',
      request
    );

    if (!response.state) {
      throw new Error();
    }

    yield put(
      systemActions.setSystem({
        ...system,
        state: response.state,
      })
    );

    yield put(systemActions.setIsReportReady(response.state.finished));
  } catch (error) {
    console.log('Error on perform calculation', error);
    showNotificationError();
  }
}

export const systemCalculationsSagas = [
  takeLatest(
    systemCalculationsActions.performFacadeSmokeHatches,
    performFacadeSmokeHatches
  ),
  takeLatest(
    systemCalculationsActions.performRoofSmokeHatches,
    performRoofSmokeHatches
  ),
  takeLatest(
    systemCalculationsActions.performCompensatingAirSupply,
    performCompensatingAirSupply
  ),
  takeLatest(systemCalculationsActions.performAirCurtains, performAirCurtains),
  takeLatest(systemCalculationsActions.performAirLocks, performAirLocks),
  takeLatest(
    systemCalculationsActions.performFireProofZones,
    performFireProofZones
  ),
  takeLatest(
    systemCalculationsActions.performSmokeFreeStairwells,
    performSmokeFreeStairwells
  ),
  takeLatest(
    systemCalculationsActions.performRemovingFromBurning,
    performRemovingFromBurning
  ),
  takeLatest(
    systemCalculationsActions.performRemovingFromAdjacentToBurning,
    performRemovingFromAdjacentToBurning
  ),
  takeLatest(
    systemCalculationsActions.performElevatorShafts,
    performElevatorShafts
  ),

  takeLatest(
    systemCalculationsActions.performAerodynamics,
    performAerodynamics
  ),
];
