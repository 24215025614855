import { FocusEvent, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Radio, SelectProps, Typography } from 'antd';
import { twMerge } from 'tailwind-merge';

import { InputNumberForm } from '@/components/form/InputNumber';
import { RadioGroupForm } from '@/components/form/Radio';
import { SelectForm } from '@/components/form/Select';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { systemModalsActions } from '@/core/redux/slices/modals/smokeExtraction/system/slice';
import { systemCalculationsActions } from '@/core/redux/slices/smokeExtraction/system/calculations/slice';
import { systemParamsSelectors } from '@/core/redux/slices/smokeExtraction/system/params/selectors';
import { systemParamsActions } from '@/core/redux/slices/smokeExtraction/system/params/slice';
import { systemSelectors } from '@/core/redux/slices/smokeExtraction/system/selectors';

import { ArrivalTimeCalculation } from './sections/ArrivalTimeCalculation/ArrivalTimeCalculation';
import { DirectConvectiveColumn } from './sections/DirectConvectiveColumn/DirectConvectiveColumn';
import { FixedHeatDissipation } from './sections/FixedHeatDissipation/FixedHeatDissipation';
import { GalleryExpiration } from './sections/GalleryExpiration/GalleryExpiration';
import { OpeningFlow } from './sections/OpeningFlow/OpeningFlow';
import { SmokeLayerCharacteristics } from './sections/SmokeLayerCharacteristics/SmokeLayerCharacteristics';
import { SprinklerFireCalculation } from './sections/SprinklerFireCalculation/SprinklerFireCalculation';

const { Title } = Typography;

export interface IRemoveFromBurningView {
  systemID: number;
}

export const RemoveFromBurningView: React.FC<IRemoveFromBurningView> = ({
  systemID,
}) => {
  const systemState = useAppSelector(systemSelectors.systemState);
  const dispatch = useAppDispatch();

  const methods = useForm({
    defaultValues: {
      fire_power: 'Фиксированная мощность тепловыделения очага пожара',
      nu: 0.9,
      fire_load:
        'Административные помещения, учебные классы школ, ВУЗов, кабинеты поликлиник',
      Qp_nsr: 14,
      psi_sr: 0.014,
      vl: 0.005,
      tau: 10,
      location: 'В городских поселениях и городских округах',
      a_max: 3.5,
      Spom: 50,
      F0: null,
      Qf: 5000,
      tr: 26,
      Tr: null,
      pr: null,
      h: 0.8,
      lsm: 140,
      Asm: 200,
      convection_column_type: 'Прямая конвективная колонка',
      z: 10,
      r: 0.7,
      z1: null,
      status: 'Факел пламени входит в дымовой слой',
      W: 5,
      H: 4,
      Aw: 2,
      Hw: 2,
      Zw: 5,
      a: null,
      Gk: null,
      cpk: null,
      Tk: null,
      alpha: null,
      cpsm: null,
      Tsm: null,
      tsm: null,
      psm: null,
      Gsm: null,
      Lsm: null,
    },
  });

  const handlePerformCalculation = () => {
    const data = methods.getValues();

    dispatch(
      systemCalculationsActions.performRemovingFromBurning({
        calc_id: systemID,
        data: data,
      })
    );
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (!systemState || systemState[name] !== value) {
      handlePerformCalculation();
    }
  };

  useEffect(() => {
    systemState && methods.reset(systemState);
  }, [systemState]);

  const convectiveColumnTypeOptions = [
    {
      label: 'Прямая конвективная колонка',
      value: 'Прямая конвективная колонка',
    },
    {
      label: 'Истечение из-под галереи',
      value: 'Истечение из-под галереи',
    },
    {
      label: 'Истечение через проем',
      value: 'Истечение через проем',
    },
  ];

  const firePowerOptions = [
    {
      label: 'Фиксированная мощность тепловыделения очага пожара',
      value: 'Фиксированная мощность тепловыделения очага пожара',
    },
    {
      label: 'Расчёт по времени прибытия пожарных подразделений',
      value: 'Расчет по времени прибытия пожарных подразделений',
    },
    {
      label: 'Расчёт при наличии спринклерного пожаротушения',
      value: 'Расчет при наличии спринклерного пожаротушения',
    },
  ];

  return (
    <FormProvider {...methods}>
      <form>
        <div className='py-4 px-2 flex flex-col gap-8'>
          <div>
            <Title level={5} className='mb-4'>
              Мощность очага пожара
            </Title>
            <RadioGroupForm
              name='fire_power'
              defaultValue='Фиксированная мощность тепловыделения очага пожара'
              options={firePowerOptions}
              vertical
              onChange={(e) => {
                methods.setValue('Qf', null as any);

                handlePerformCalculation();
              }}
              className='flex flex-col gap-2'
            />
          </div>

          {methods.watch('fire_power') ===
            'Фиксированная мощность тепловыделения очага пожара' && (
            <FixedHeatDissipation handleBlur={handleBlur} />
          )}

          {methods.watch('fire_power') ===
            'Расчет по времени прибытия пожарных подразделений' && (
            <ArrivalTimeCalculation
              handleBlur={handleBlur}
              handlePerformCalculation={handlePerformCalculation}
            />
          )}

          {methods.watch('fire_power') ===
            'Расчет при наличии спринклерного пожаротушения' && (
            <SprinklerFireCalculation
              handleBlur={handleBlur}
              handlePerformCalculation={handlePerformCalculation}
            />
          )}

          <div>
            <Title level={5}>Характеристика помещения</Title>

            <div className='py-4 grid grid-cols-2 gap-4 gap-y-8 items-end'>
              <InputNumberForm
                name='tr'
                addonBefore={
                  <i>
                    t<sub>r</sub> =
                  </i>
                }
                addonAfter={<i>°C</i>}
                label='Температура воздуха в помещении'
                onBlur={handleBlur}
                size='large'
              />
            </div>
            <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
              <InputNumberForm
                addonBefore={
                  <i>
                    T<sub>r</sub> =
                  </i>
                }
                addonAfter={<i>°К</i>}
                name='Tr'
                label='Температура воздуха в помещении'
                disabled
                onBlur={handleBlur}
                size='large'
              />
              <InputNumberForm
                name='pr'
                addonBefore={
                  <i>
                    ρ<sub>r</sub> =
                  </i>
                }
                addonAfter={
                  <i>
                    кг/м<sup>3</sup>
                  </i>
                }
                label='Плотность воздуха'
                disabled
                onBlur={handleBlur}
                size='large'
              />
            </div>

            <div className='py-4'>
              <Title level={5}>Характеристики дымового слоя</Title>
            </div>

            <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
              <InputNumberForm
                name='h'
                addonBefore={<i>h =</i>}
                addonAfter={<i>м</i>}
                label='Толщина образующегося дымового слоя'
                onBlur={handleBlur}
                size='large'
              />
              <InputNumberForm
                name='lsm'
                addonBefore={
                  <i>
                    l<sub>sm</sub> =
                  </i>
                }
                addonAfter={<i>м</i>}
                label='Максимальный периметр горизонтального сечения дымового слоя'
                onBlur={handleBlur}
                size='large'
              />
              <InputNumberForm
                name='Asm'
                addonBefore={
                  <i>
                    A<sub>sm</sub> =
                  </i>
                }
                addonAfter={
                  <i>
                    м<sup>2</sup>
                  </i>
                }
                label='Эквивалентная площадь сечения дымового слоя в горизонтальной плоскости'
                onBlur={handleBlur}
                size='large'
              />
            </div>
          </div>

          <div>
            <RadioGroupForm
              name='convection_column_type'
              defaultValue='Прямая конвективная колонка'
              buttonStyle='outline'
              options={convectiveColumnTypeOptions}
              optionType='button'
              label='Тип конвективной колонки'
              onChange={handlePerformCalculation}
            />
          </div>
          {methods.watch('convection_column_type') ===
            'Прямая конвективная колонка' && (
            <DirectConvectiveColumn handleBlur={handleBlur} />
          )}

          {methods.watch('convection_column_type') ===
            'Истечение из-под галереи' && (
            <GalleryExpiration handleBlur={handleBlur} />
          )}

          {methods.watch('convection_column_type') ===
            'Истечение через проем' && <OpeningFlow handleBlur={handleBlur} />}

          <SmokeLayerCharacteristics handleBlur={handleBlur} />
        </div>
      </form>
    </FormProvider>
  );
};
