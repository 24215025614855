import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { LoadingStatus } from '@/core/enums/loadingStatus';
import type oas from '@/services/rest/base/openapi';

import {
  airExchangeActions,
  IAirExchangeList,
  IAirExchangeListFetchPayload,
} from './slice';

type AirExchangeListResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/air_exchange',
  'get',
  '200'
>;

function* fetchAirExchangeList(
  action: PayloadAction<IAirExchangeListFetchPayload>
): Generator<any, void, AirExchangeListResponse> {
  const { search, order_by, status, page, size } = action.payload;

  yield put(airExchangeActions.setAirExchangeListLock(LoadingStatus.LOADING));

  try {
    const response = yield call(restCall, '/air_exchange', 'get', {
      query: { search, order_by, status, page, size },
      ...authAdd(),
    });

    const airExchangeList: IAirExchangeList = response;
    
    yield put(airExchangeActions.setAirExchangeList(airExchangeList));
    yield put(airExchangeActions.setAirExchangeListLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on air exchange list fetching');
    yield put(airExchangeActions.setAirExchangeListLock(LoadingStatus.ERROR));
  }
}

export const airExchangeSagas = [
  takeLatest(airExchangeActions.fetchAirExchangeList, fetchAirExchangeList),
];
