import { ReactNode, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Modal, Typography } from 'antd';
import * as yup from 'yup';

import { InputForm } from '@/components/form/Input';
import { TextAreaForm } from '@/components/form/TextArea';
import { TourItem } from '@/components/ui/TourItem/TourItem';
import { LoadingStatus } from '@/core/enums/loadingStatus';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { buildingObjectModalsSelectors } from '@/core/redux/slices/modals/smokeExtraction/buildingObject/selectors';
import { buildingObjectModalsActions } from '@/core/redux/slices/modals/smokeExtraction/buildingObject/slice';
import { buildingObjectSelectors } from '@/core/redux/slices/smokeExtraction/buildingObject/selectors';
import { buildingObjectActions } from '@/core/redux/slices/smokeExtraction/buildingObject/slice';

const schema = yup.object().shape({
  name: yup.string().required(),
  address: yup.string().required(),
  comment: yup.string(),
});

interface IBuildingObjectForm {
  name: string;
  address: string;
  comment?: string;
}

const ModalContent: React.FC = () => {
  const methods = useForm<IBuildingObjectForm>({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();

  const { Text, Title } = Typography;
  const dispatch = useAppDispatch();

  const { isRender } = useAppSelector(
    buildingObjectModalsSelectors.addBuildingObjectModal
  );

  const { response, status } = useAppSelector(
    buildingObjectSelectors.addBuildingObjectLock
  );

  const handleCancel = () => {
    dispatch(buildingObjectModalsActions.closeAddBuildingObjectModal());
  };

  const handleCreate = (data: IBuildingObjectForm) => {
    dispatch(buildingObjectActions.addBuildingObject(data));
  };

  useEffect(() => {
    if (status !== LoadingStatus.LOADED || !response) {
      return;
    }

    navigate(`/objects/${response.id}`);
    handleCancel();
  }, [status]);

  useEffect(() => {
    return () => {
      dispatch(
        buildingObjectActions.setAddBuildingObjectLock({
          status: LoadingStatus.NEVER,
          response: null,
        })
      );
    };
  }, []);

  const ModalHeader: React.FC = () => {
    return (
      <div>
        <Title level={4}>Новый объект</Title>
        <Text>ID объекта: --</Text>
      </div>
    );
  };

  const ModalFooter: React.FC = () => {
    return (
      <div className='flex gap-2'>
        <Button className='w-1/2' onClick={handleCancel}>
          Отменить
        </Button>
        <TourItem
          content={
            'Заполните информацию об объекте, затем нажмите “Создать объект”'
          }
          placement='bottomRight'
          step={3}
          className='w-[292px]'
        >
          <Button
            className='w-1/2'
            type='primary'
            disabled={!methods.formState.isValid}
            onClick={() => handleCreate(methods.getValues())}
          >
            Создать объект
          </Button>
        </TourItem>
      </div>
    );
  };

  return (
    <Modal
      open={isRender}
      destroyOnClose
      onCancel={handleCancel}
      centered
      footer={null}
    >
      <ModalHeader />
      <div className='my-6'>
        <FormProvider {...methods}>
          <form>
            <div className='flex flex-col gap-4'>
              <InputForm
                name='name'
                label='Название объекта'
                placeholder='Введите название объекта'
              />
              <InputForm
                name='address'
                label='Адрес объекта'
                placeholder='Введите адрес'
              />
              <TextAreaForm
                name='comment'
                label='Комментарий'
                placeholder='Добавьте комментарий (необязательно)'
              />
            </div>
          </form>
        </FormProvider>
      </div>
      <ModalFooter />
    </Modal>
  );
};

export const AddBuildingObjectModal: React.FC = () => {
  const { isRender } = useAppSelector(
    buildingObjectModalsSelectors.addBuildingObjectModal
  );

  if (isRender) {
    return <ModalContent />;
  }

  return null;
};
