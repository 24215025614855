import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Typography } from 'antd';

import { InputNumberForm } from '@/components/form/InputNumber';
import GalleryImage from '@/images/QA_42/Gallary.jpg';

interface IGalleryExpiration {
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const { Text } = Typography;

export const GalleryExpiration: React.FC<IGalleryExpiration> = ({
  handleBlur,
}) => {
  const methods = useFormContext();
  return (
    <>
      <img src={GalleryImage} className='w-[900px] h-auto' />
      <div className='grid grid-cols-3 gap-4 gap-y-8 items-end mb-4'>
        <InputNumberForm
          name='r'
          addonBefore={<i>r =</i>}
          label='Коэффициент, характеризующий теплопотери на излучение'
          note='0,7 - согласно п. 5.5.1.4 NFPA 92-24'
          onBlur={handleBlur}
          size='large'
        />
      </div>
      <div className='grid grid-cols-3 gap-4 gap-y-8 items-start'>
        <div>
          <InputNumberForm
            name='W'
            addonBefore={<i>W =</i>}
            addonAfter={<i>м</i>}
            label='Начальная ширина струи газообразных продуктов горения, истекающих из-под ограждений галереи'
            onBlur={handleBlur}
            size='large'
          />
          <Text type='secondary' className='text-xs'>
            При наличии противодымных экранов у выхода из горящего помещения W -
            расстояние между ними (см. изображение) <br />
            При отсутствии противодымных экранов W = w + b где: <br />
            w - ширина проема горящего помещения из которого выходит дым, м (см,
            изображение); <br />b - расстояние от проема до выступа балкона, м
            (см. изображение).
          </Text>
        </div>
        <div>
          <InputNumberForm
            name='z'
            addonBefore={<i>z =</i>}
            addonAfter={<i>м</i>}
            label='Высота от уровня ограждения галереи, омываемого истекающей струей, до нижней границы дымового слоя'
            onBlur={handleBlur}
            size='large'
          />
          {methods.watch('W') > 14 && methods.watch('z') >= 15 && (
            <Text className='text-red-400'>
              При z ≥ 15 м, W должно быть не больше 14 м
            </Text>
          )}
        </div>

        <InputNumberForm
          name='H'
          addonBefore={<i>H =</i>}
          addonAfter={<i>м</i>}
          label='Высота от основания помещения до уровня ограждения галереи, омываемого истекающей струей'
          onBlur={handleBlur}
          size='large'
        />
      </div>
      <div className='grid grid-cols-3 gap-4 gap-y-8 items-end'>
        <InputNumberForm
          name='Gk'
          addonBefore={
            <i>
              G<sub>k</sub> =
            </i>
          }
          addonAfter={<i>кг/с</i>}
          label='Массовый расход дымовых газов в конвективной колонке'
          onBlur={handleBlur}
          fixValue={2}
          disabled
          size='large'
        />
        <InputNumberForm
          name='cpk'
          addonBefore={
            <i>
              с<sub>pk</sub> =
            </i>
          }
          addonAfter={<i>кДж/(кг*°К)</i>}
          label='Удельная теплоемкость дымовых газов в конвективной колонке при температуре Tk'
          onBlur={handleBlur}
          disabled
          size='large'
        />
        <InputNumberForm
          name='Tk'
          addonBefore={
            <i>
              T<sub>k</sub> =
            </i>
          }
          addonAfter={<i>°К</i>}
          disabled
          label='Температура дымовых газов в конвективной колонке'
          fixValue={1}
          onBlur={handleBlur}
          size='large'
        />
      </div>
    </>
  );
};
