import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Card } from 'antd';

import styles from './styles.module.scss';

export const AuthLayout: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [activeTabKey, setActiveTabKey] = useState<string>('');

  const tabListNoTitle = [
    {
      key: '/auth/login',
      label: 'Войти в профиль',
    },
    {
      key: '/auth/signup',
      label: 'Создать аккаунт',
    },
  ];

  const onTabChange = (key: string) => {
    setActiveTabKey(location.pathname)
    navigate(key);
  };

  useEffect(() => {
    setActiveTabKey(location.pathname)
  }, [location])

  return (
    <Card
      tabList={tabListNoTitle}
      className={styles.card}
      defaultActiveTabKey={location.pathname}
      activeTabKey={activeTabKey}
      tabProps={{
        centered: true,
      }}
      style={{
        width: 368,
      }}
      onTabChange={onTabChange}
    >
      <Outlet />
    </Card>
  );
};
