import React, { useEffect } from 'react';
import { setDefaultOptions } from 'date-fns';
import { ru } from 'date-fns/locale';

import useAuth from '@/core/hooks/useAuth';
import PrivateRoutes from '@/routes/PrivateRoutes';
import PublicRoutes from '@/routes/PublicRoutes';

export const App: React.FC = () => {
  const { isAuthenticated, isInitialized } = useAuth();

  useEffect(() => {
    setDefaultOptions({ locale: ru });
  }, []);

  return (
    isInitialized && (isAuthenticated ? <PrivateRoutes /> : <PublicRoutes />)
  );
};

export default App;
