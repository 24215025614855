import { Controller, useFormContext } from 'react-hook-form';
import { Input, Typography } from 'antd';
import { TextAreaProps } from 'antd/es/input';

interface TextAreaForm extends TextAreaProps {
  label?: string;
  name: string;
  defaultValue?: string;
}

const { Text } = Typography;
const { TextArea } = Input;

export const TextAreaForm: React.FC<TextAreaForm> = (props) => {
  const { label, name, defaultValue } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = errors[name];

  return (
    <>
      <div className='flex flex-col gap-1'>
        {label && (
          <label htmlFor={name} className='text-secondary'>
            {label}
          </label>
        )}
        <Controller
          render={({ field }) => (
            <TextArea {...props} {...field} status={error && 'error'} />
          )}
          control={control}
          name={name}
          defaultValue={defaultValue}
        />
      </div>

      {error && (
        <Text className='block' type='danger'>
          {String(error.message)}
        </Text>
      )}
    </>
  );
};
