import { FocusEvent, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Radio, SelectProps, Typography } from 'antd';
import _ from 'lodash';
import { twMerge } from 'tailwind-merge';

import { InputNumberForm } from '@/components/form/InputNumber';
import { RadioGroupForm } from '@/components/form/Radio';
import { DebounceSelect } from '@/components/ui/DebounceSelect/DebounceSelect';
import { LoadingStatus } from '@/core/enums/loadingStatus';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { systemModalsActions } from '@/core/redux/slices/modals/smokeExtraction/system/slice';
import { systemCalculationsActions } from '@/core/redux/slices/smokeExtraction/system/calculations/slice';
import { systemParamsSelectors } from '@/core/redux/slices/smokeExtraction/system/params/selectors';
import { systemParamsActions } from '@/core/redux/slices/smokeExtraction/system/params/slice';
import { systemSelectors } from '@/core/redux/slices/smokeExtraction/system/selectors';

const { Title } = Typography;

export interface ISmokeFreeStairwellsView {
  systemID: number;
}

const { Text } = Typography;

export const SmokeFreeStairwellsView: React.FC<ISmokeFreeStairwellsView> = ({
  systemID,
}) => {
  const cityParams = useAppSelector(systemParamsSelectors.cityParams);
  const cityParamsLock = useAppSelector(systemParamsSelectors.cityParamsLock);
  const systemState = useAppSelector(systemSelectors.systemState);
  const dispatch = useAppDispatch();

  const methods = useForm<any>({
    defaultValues: {
      ftype: 'Надземные',
      city_id: 1,
      city: 'Москва',
      ta: 0,
      Ta: null,
      va: 0,
      pa: null,
      stairwells_type: 0,
      checkbox_value: 0,
      kaww: 0.8,
      kaw0: -0.6,
      tr: 18,
      Tr: null,
      pr: null,
      Ts: null,
      ps: null,
      N: 10,
      h2: null,
      h_n: -6,
      h_1: -3,
      hte: 3,
      hda: 2.1,
      bda: 1,
      Fda: null,
      n: 1,
      Ed: 2.44,
      Er: null,
      door_type: 'Противопожарная',
      Sd: null,
      hdi: 2.1,
      bdi: 0.9,
      Fdi: null,
      Es: 60,
      Fs: 18.5,
      z: 1,
      Fw: 2,
      Rn: 0.433,
      Gsm: 2.7,
      p: 1,
      q: null,
      Ps: null,
      Gsa: null,
      Gs_n: null,
      Gs: null,
      result_table: [],
      Gsn: null,
      PsN: null,
      Pds: null,
      Lv: null,
      hsN: null,
      h0s: null,
      Psv: null,
      hd1: 2.1,
    },
  });

  const systemTypeID = methods.watch('checkbox_value');
  const currentView = methods.watch('ftype');

  const isHideGsa =
    methods.watch('door_type') === 'Противопожарная' && systemTypeID === 0;

  const isShowGsa = systemTypeID === 1 || systemTypeID === 0;

  const cityParamsOptions: SelectProps['options'] = useMemo(() => {
    if (!cityParams) {
      return [];
    }

    return cityParams.map((item) => ({
      value: item.id,
      label: item.city,
    }));
  }, [cityParams]);

  const handlePerformCalculation = () => {
    const data = methods.getValues();

    const currentStairwellType = () => {
      if (data['ftype'] === 'Подземные') {
        return 6;
      }

      if (data['checkbox_value'] === 0 || data['checkbox_value'] === 1) {
        return 1;
      }

      return data['checkbox_value'];
    };

    dispatch(
      systemCalculationsActions.performSmokeFreeStairwells({
        calc_id: systemID,
        data: {
          ...data,
          stairwells_type: currentStairwellType(),
        },
      })
    );
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (!systemState || systemState[name] !== value) {
      handlePerformCalculation();
    }
  };

  const onSelectedCityChange = (id: string) => {
    if (!cityParams) {
      return;
    }

    const selectedCityID = Number(id);

    const selectedCity = cityParams.find((item) => item.id === selectedCityID);

    if (selectedCity) {
      methods.setValue('city', String(selectedCity.city));
      methods.setValue('ta', Number(selectedCity.cold_temp));
      methods.setValue('va', Number(selectedCity.max_speed));

      handlePerformCalculation();
    }
  };

  const handleOpenResultTable = () => {
    const data = methods.getValues();
    const tableData = data.result_table;

    dispatch(
      systemModalsActions.openDiplayResultModal({
        resultTableData: tableData,
        systemTypeID: systemTypeID,
        currentView: currentView,
      })
    );
  };

  useEffect(() => {
    systemState && methods.reset(systemState);
  }, [systemState]);

  const stairwellsViewsOptions = [
    { label: 'Надземные', value: 'Надземные' },
    { label: 'Подземные', value: 'Подземные' },
  ];

  const doorTypeOptions = [
    { label: 'Противопожарная', value: 'Противопожарная' },
    { label: 'Дымогазонепроницаемая', value: 'Дымогазонепроницаемая' },
  ];

  const stairwellsTypeOptions = [
    {
      label: `Примыкающие к наружным ограждающим конструкциям с устройством обособленного наружного выхода, с отсутствием внутренней связи (дверных проемов, сообщающихся с прилегающими коридорами, холлами, вестибюлями) на уровне нижнего надземного (первого) этажа`,
      value: 0,
    },
    {
      label: `Примыкающие к наружным ограждающим конструкциям с устройством обособленного наружного выхода, при дополнительном их сообщении с холлами, вестибюлями нижнего надземного этажа через тамбур-шлюзы, защищаемые автономными системами приточной противодымной вентиляции`,
      value: 1,
    },
    {
      label: `Примыкающие к наружным ограждающим конструкциям с устройством обособленного наружного выхода, с устройством поэтажных выходов через тамбур-шлюзы защищаемые автономными системами приточной противодымной вентиляции`,
      value: 2,
    },
    {
      label: `Примыкающие к наружным ограждающим конструкциям с устройством обособленного наружного выхода, при дополнительном выходе в лестничную клетку из вестибюля, холла или коридора нижнего этажа через одинарную дверь`,
      value: 3,
    },
    {
      label: `Расположенные в центральном ядре и имеющие изолированные наружные выходы (без дополнительного сообщения с вестибюлем, холлом или коридором нижнего надземного этажа)`,
      value: 4,
    },
    {
      label: `Расположенные в центральном ядре и не имеющие непосредственного выхода наружу, с устройством тамбур-шлюза, автономно защищаемого приточной противодымной вентиляцией и отделяющего внутренние выходы таких лестничных клеток от вестибюля (помещений входной группы здания)`,
      value: 5,
    },
  ];

  const isHideWindows = systemTypeID === 4 || systemTypeID === 5;

  useEffect(() => {
    if (!systemState) {
      dispatch(
        systemParamsActions.fetchCityParams({
          page: 1,
          size: 10,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (cityParamsLock === LoadingStatus.LOADED) {
      !systemState && cityParams && onSelectedCityChange('1');
    }
  }, [cityParams]);

  return (
    <FormProvider {...methods}>
      <form>
        <div className='py-4 px-2 flex flex-col gap-8'>
          <div className='flex flex-col gap-3'>
            <RadioGroupForm
              name='ftype'
              defaultValue='Надземные'
              buttonStyle='outline'
              options={stairwellsViewsOptions}
              optionType='button'
              onChange={(e) => {
                if (e.target.value === 'Надземные') {
                  methods.setValue('N', 10);
                } else {
                  methods.setValue('N', -2);
                }
                handlePerformCalculation();
              }}
            />
            {currentView === 'Подземные' && (
              <Typography>
                С непосредственным и конструктивно изолированным выходом наружу
                на уровне нижнего этажа надземной части здания
              </Typography>
            )}
          </div>

          {currentView === 'Надземные' && (
            <RadioGroupForm
              name='checkbox_value'
              options={stairwellsTypeOptions}
              vertical
              onChange={(e) => {
                handlePerformCalculation();
              }}
            />
          )}
        </div>

        <div className='py-4 px-2'>
          <Title level={5} className='mb-4'>
            Город
          </Title>
          <DebounceSelect
            size='large'
            showSearch
            placeholder='Выберите город'
            defaultValue={{
              value: methods.getValues('city_id'),
              label: methods.getValues('city'),
            }}
            value={{
              value: methods.getValues('city_id'),
              label: methods.getValues('city'),
            }}
            style={{ width: '100%' }}
            onChange={(newValue: any) => {
              methods.setValue('city_id', newValue.key);
              onSelectedCityChange(newValue.key);
            }}
            options={cityParamsOptions}
            fetchOptions={(search: string) => {
              dispatch(
                systemParamsActions.fetchCityParams({
                  page: 1,
                  size: 10,
                  search: search,
                })
              );
            }}
            loading={cityParamsLock === LoadingStatus.LOADING}
          />
        </div>

        <div className='py-4 px-2'>
          <div className='flex flex-col gap-8'>
            <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
              <InputNumberForm
                name='ta'
                addonBefore={
                  <i>
                    t<sub>a</sub> =
                  </i>
                }
                addonAfter={'°C'}
                label={
                  currentView === 'Подземные'
                    ? 'Температура воздуха в помещениях здания'
                    : 'Температура наружного воздуха'
                }
                disabled
                onBlur={handleBlur}
                size='large'
              />
              <InputNumberForm
                name='pa'
                addonBefore={
                  <i>
                    ρ<sub>a</sub> =
                  </i>
                }
                addonAfter={
                  <i>
                    кг/м<sup>3</sup>
                  </i>
                }
                label='Плотность наружного воздуха'
                disabled
                onBlur={handleBlur}
                size='large'
              />
              <InputNumberForm
                addonBefore={
                  <i>
                    T<sub>a</sub> =
                  </i>
                }
                addonAfter={<i>°К</i>}
                name='Ta'
                label='Температура наружного воздуха'
                disabled
                onBlur={handleBlur}
                size='large'
              />

              {systemTypeID !== 5 && (
                <>
                  <InputNumberForm
                    addonBefore={
                      <i>
                        v<sub>a</sub> =
                      </i>
                    }
                    addonAfter='м/с'
                    name='va'
                    disabled
                    label='Скорость ветра '
                    onBlur={handleBlur}
                    size='large'
                  />
                  <InputNumberForm
                    addonBefore={
                      <i>
                        k<sub>aww</sub> =
                      </i>
                    }
                    name='kaww'
                    label='Коэффициент ветрового напора для наветренного фасада'
                    placeholder='Введите значение'
                    onBlur={handleBlur}
                    size='large'
                  />
                  <InputNumberForm
                    addonBefore={
                      <i>
                        k<sub>aw0</sub> =
                      </i>
                    }
                    name='kaw0'
                    label='Коэффициент ветрового напора для заветренного фасада '
                    placeholder='Введите значение'
                    onBlur={handleBlur}
                    size='large'
                  />
                </>
              )}
            </div>

            <div className='py-4 px-2'>
              <Title level={5} className='mb-4'>
                {currentView === 'Надземные'
                  ? 'Параметры надземной части'
                  : 'Подземная часть'}
              </Title>
              <div className='flex flex-col gap-8'>
                <InputNumberForm
                  name='tr'
                  addonBefore={
                    <i>
                      t<sub>r</sub> =
                    </i>
                  }
                  addonAfter={'°C'}
                  label={
                    currentView === 'Подземные'
                      ? 'Температура воздуха в помещениях здания'
                      : 'Температура наружного воздуха'
                  }
                  onBlur={handleBlur}
                  size='large'
                />
                <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
                  <InputNumberForm
                    addonBefore={
                      <i>
                        T<sub>r</sub> =
                      </i>
                    }
                    addonAfter={<i>°К</i>}
                    name='Tr'
                    label='Температура наружного воздуха'
                    disabled
                    onBlur={handleBlur}
                    size='large'
                  />
                  <InputNumberForm
                    name='pr'
                    addonBefore={
                      <i>
                        ρ<sub>r</sub> =
                      </i>
                    }
                    addonAfter={
                      <i>
                        кг/м<sup>3</sup>
                      </i>
                    }
                    label='Плотность воздуха в помещениях здания'
                    disabled
                    onBlur={handleBlur}
                    size='large'
                  />

                  <InputNumberForm
                    addonBefore={
                      <i>
                        T<sub>s</sub> =
                      </i>
                    }
                    addonAfter={<i>°К</i>}
                    name='Ts'
                    label='Температура воздуха в защищаемых лестничных узлах'
                    disabled
                    onBlur={handleBlur}
                    size='large'
                  />
                  <InputNumberForm
                    name='ps'
                    addonBefore={
                      <i>
                        ρ<sub>s</sub> =
                      </i>
                    }
                    addonAfter={
                      <i>
                        кг/м<sup>3</sup>
                      </i>
                    }
                    label='Плотность воздуха в защищаемых лестничных узлах'
                    disabled
                    onBlur={handleBlur}
                    size='large'
                  />
                </div>
              </div>
            </div>

            <div className='py-4 px-2'>
              <Title level={5}>Параметры здания</Title>
              <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
                {methods.watch('ftype') === 'Подземные' ? (
                  <>
                    <InputNumberForm
                      name='N'
                      addonBefore={<i>N =</i>}
                      label='Последний подземный этаж'
                      onBlur={handleBlur}
                      size='large'
                    />
                    <InputNumberForm
                      name='h_1'
                      addonBefore={
                        <i>
                          h<sub>-1</sub> =
                        </i>
                      }
                      addonAfter={<i>м</i>}
                      label='Уровень отметки пола -1-го подземного этажа относительно уровня отметки пола нижнего надземного этажа'
                      note='отрицательная величина'
                      onBlur={handleBlur}
                      size='large'
                    />
                    <InputNumberForm
                      name='h_n'
                      addonBefore={
                        <i>
                          h<sub>-n</sub> =
                        </i>
                      }
                      addonAfter={<i>м</i>}
                      label='Глубина подземной части или уровень отметки пола нижнего подземного этажа относительно уровня отметки пола нижнего надземнего этажа'
                      onBlur={handleBlur}
                      note='отрицательная величина'
                      size='large'
                    />
                    <InputNumberForm
                      name='hte'
                      addonBefore={
                        <i>
                          h<sub>тэ</sub> =
                        </i>
                      }
                      addonAfter={<i>м</i>}
                      label='Высота типового этажа'
                      onBlur={handleBlur}
                      size='large'
                    />
                  </>
                ) : (
                  <>
                    <InputNumberForm
                      name='N'
                      addonBefore={<i>N =</i>}
                      label='Количество этажей'
                      onBlur={handleBlur}
                      size='large'
                    />

                    <InputNumberForm
                      name='h2'
                      addonBefore={
                        <i>
                          h<sub>2</sub> =
                        </i>
                      }
                      addonAfter={<i>м</i>}
                      label='Отметка пола 2 этажа (от уровня планировочной отметки нижнего надземного этажа)'
                      onBlur={handleBlur}
                      size='large'
                    />
                    <InputNumberForm
                      name='hte'
                      addonBefore={
                        <i>
                          h<sub>тэ</sub> =
                        </i>
                      }
                      addonAfter={<i>м</i>}
                      label='Высота типового этажа'
                      onBlur={handleBlur}
                      size='large'
                    />
                  </>
                )}
              </div>
            </div>

            {((systemTypeID !== 5) || (systemTypeID === 5 && currentView === 'Подземные')) && (
              <>
                <div className='py-4 px-2 flex flex-col gap-8'>
                  <Title level={5}>
                    Двери лестничной клетки для наружного выхода
                  </Title>
                  <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
                    <InputNumberForm
                      name='hda'
                      addonBefore={
                        <i>
                          h<sub>da</sub> =
                        </i>
                      }
                      addonAfter={<i>м</i>}
                      label='Высота двери (створки) наружного выхода лестничной клетки'
                      onBlur={handleBlur}
                      size='large'
                    />
                    <InputNumberForm
                      name='bda'
                      addonBefore={
                        <i>
                          b<sub>da</sub> =
                        </i>
                      }
                      addonAfter={<i>м</i>}
                      label='Ширина двери (створки) наружного выхода лестничной клетки'
                      onBlur={handleBlur}
                      size='large'
                    />
                    <InputNumberForm
                      name='n'
                      addonBefore={<i>n =</i>}
                      addonAfter={<i>шт</i>}
                      label='Количество последовательно расположенных дверей наружного выхода лестничной клетки'
                      onBlur={handleBlur}
                      size='large'
                    />
                    <InputNumberForm
                      name='Fda'
                      addonBefore={
                        <i>
                          F<sub>da</sub> =
                        </i>
                      }
                      addonAfter={
                        <i>
                          м<sup>2</sup>
                        </i>
                      }
                      disabled
                      label='Площадь двери (створки) наружного выхода лестничной клетки'
                      fixValue={2}
                      onBlur={handleBlur}
                      size='large'
                    />
                  </div>

                  <div className='grid grid-cols-2 gap-4 gap-y-8 items-start'>
                    <InputNumberForm
                      name='Ed'
                      addonBefore={
                        <i>
                          ξ<sub>d</sub> =
                        </i>
                      }
                      disabled
                      label='Коэффициент местного сопротивления дверного проема'
                      onBlur={handleBlur}
                      size='large'
                    />

                    <div>
                      <InputNumberForm
                        name='Er'
                        addonBefore={
                          <i>
                            ξ<sub>r</sub> =
                          </i>
                        }
                        label='Коэффициент местного сопротивления тамбура у наружного выхода лестничной клетки'
                        onBlur={handleBlur}
                        size='large'
                      />
                      <Text type='secondary' className='p-2 block'>
                        0 - прямой тамбур <br /> 0,99 - прямоугольный тамбур{' '}
                        <br /> 2,9÷4,0 - z-образный угол
                      </Text>
                    </div>
                  </div>
                </div>
              </>
            )}

            {methods.watch('stairwells_type') === 3 && (
              <div>
                <Title level={5}>
                  Выход в лестничную клетку из вестибюля, холла или коридора
                  нижнего этажа через одинарную дверь
                </Title>
                <InputNumberForm
                  name='hd1'
                  addonBefore={
                    <i>
                      h<sub>d1</sub> =
                    </i>
                  }
                  addonAfter={<i>м</i>}
                  label='Высота двери (створки) выхода в лестничную клетку из вестибюля, холла или коридора нижнего этажа (1-го этажа)'
                  onBlur={handleBlur}
                  size='large'
                />
              </div>
            )}

            <div>
              <Title level={5}>Двери лестничной клетки внутренние</Title>
              <div className='py-4'>
                <RadioGroupForm
                  name='door_type'
                  defaultValue='Противопожарная'
                  buttonStyle='outline'
                  label='Тип двери'
                  options={doorTypeOptions}
                  optionType='button'
                  onChange={handlePerformCalculation}
                />
              </div>
              <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
                <InputNumberForm
                  name='hdi'
                  addonBefore={
                    currentView === 'Надземные' ? (
                      <i>
                        h<sub>d(i)</sub> =
                      </i>
                    ) : (
                      <i>
                        h<sub>d(-i)</sub> =
                      </i>
                    )
                  }
                  addonAfter={<i>м</i>}
                  label='Высота двери типового этажа'
                  onBlur={handleBlur}
                  size='large'
                />
                <InputNumberForm
                  name='bdi'
                  addonBefore={
                    currentView === 'Надземные' ? (
                      <i>
                        b<sub>d(i)</sub> =
                      </i>
                    ) : (
                      <i>
                        b<sub>d(-i)</sub> =
                      </i>
                    )
                  }
                  addonAfter={<i>м</i>}
                  label='Ширина двери типового этажа'
                  onBlur={handleBlur}
                  size='large'
                />
                <InputNumberForm
                  name='Sd'
                  addonBefore={
                    <i>
                      S<sub>d</sub> =
                    </i>
                  }
                  addonAfter={
                    <i>
                      м<sup>3</sup>/кг
                    </i>
                  }
                  label='Удельное сопротивление воздухопроницанию закрытых дверей лестничной клетки'
                  fixValue={0}
                  onBlur={handleBlur}
                  disabled
                  size='large'
                />
                <InputNumberForm
                  name='Fdi'
                  addonBefore={
                    currentView === 'Надземные' ? (
                      <i>
                        F<sub>d(i)</sub> =
                      </i>
                    ) : (
                      <i>
                        F<sub>d(-i)</sub> =
                      </i>
                    )
                  }
                  addonAfter={
                    <i>
                      м<sup>2</sup>
                    </i>
                  }
                  disabled
                  label='Площадь двери типового этажа'
                  fixValue={2}
                  onBlur={handleBlur}
                  size='large'
                />
              </div>
            </div>

            <div>
              <Title level={5}>Параметры лестничной клетки</Title>

              <div className='grid grid-cols-3 gap-4 gap-y-8 items-end pb-6'>
                <InputNumberForm
                  name='Es'
                  addonBefore={
                    <i>
                      ξ<sub>s</sub> =
                    </i>
                  }
                  label='Коэффициент сопротивления лестничной клетки'
                  onBlur={handleBlur}
                  note='60 - согласно МД.137-13'
                  size='large'
                />
                <InputNumberForm
                  name='Fs'
                  addonBefore={
                    <i>
                      F<sub>s</sub> =
                    </i>
                  }
                  addonAfter={
                    <i>
                      м<sup>2</sup>
                    </i>
                  }
                  label='Площадь горизонтальной проекции маршей и площадок лестничной клетки'
                  onBlur={handleBlur}
                  size='large'
                />

                {systemTypeID !== 5 ||
                  (systemTypeID !== 3 && (
                    <InputNumberForm
                      name='z'
                      addonBefore={<i>z =</i>}
                      label='Коэффициент сопротивления маршей лестничной клетки'
                      note='1 - согласно МД.137-13'
                      onBlur={handleBlur}
                      size='large'
                    />
                  ))}
              </div>
            </div>

            {/*Оконные проемы */}

            {currentView !== 'Подземные' && (
              <>
                {!isHideWindows && (
                  <div>
                    <Title level={5}>Параметры оконных проемов</Title>
                    <div className='grid grid-cols-2 gap-4 gap-y-8'>
                      <InputNumberForm
                        name='Fw'
                        addonBefore={
                          <i>
                            F<sub>w</sub> =
                          </i>
                        }
                        addonAfter={
                          <i>
                            м<sup>2</sup>
                          </i>
                        }
                        label='Площадь оконного проема на этаже'
                        onBlur={handleBlur}
                        size='large'
                      />
                      <div>
                        <InputNumberForm
                          name='Rn'
                          addonBefore={
                            <i>
                              R<sub>n</sub> =
                            </i>
                          }
                          addonAfter={
                            <i>
                              м<sup>2</sup>ч/кг
                            </i>
                          }
                          label='Сопротивление воздухопроницанию заполнений оконных проемов'
                          onBlur={handleBlur}
                          size='large'
                        />

                        <Text type='secondary' className='p-2 block'>
                          1,299 - Класс А <br /> 0,433 - Класс Б <br /> 0,229 -
                          Класс В <br /> 0,144 - Класс Г <br /> 0,078 - Класс Д
                        </Text>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            {/* Массовый расход воздуха, поступающего через открытый дверной проем на этаже пожара */}

            {systemTypeID !== 2 && (
              <div>
                <Title level={5}>
                  Определение массового расхода воздуха через открытые дверные
                  проемы при пожаре
                </Title>
                <div className='grid grid-cols-2 gap-4 gap-y-8 py-4 items-end'>
                  <InputNumberForm
                    name='Gsm'
                    addonBefore={
                      <i>
                        G<sub>sm</sub> =
                      </i>
                    }
                    addonAfter={<i>кг/с</i>}
                    label='Массовый расход удаляемых продуктов горения'
                    onBlur={handleBlur}
                    size='large'
                  />
                  <InputNumberForm
                    name='p'
                    addonBefore={<i>p =</i>}
                    label='Фактическое количество лестничных клеток, имеющих выходы в тот же коридор (помещение) того же этажа и защищаемых приточной противодымных вентиляцией'
                    onBlur={handleBlur}
                    size='large'
                  />
                  {/* <InputNumberForm
                    name='Ps'
                    addonBefore={
                      currentView === 'Подземные' ? (
                        <i>
                          P<sub>s(-1)</sub> =
                        </i>
                      ) : systemTypeID === 3 ? (
                        <i>
                          P<sub>s1</sub> =
                        </i>
                      ) : (
                        <i>
                          P<sub>s2</sub> =
                        </i>
                      )
                    }
                    addonAfter={<i>Па</i>}
                    label={
                      currentView === 'Подземные'
                        ? 'Внутреннее давление на уровне геометрического центра двери лестничной клетки -1-го этажа'
                        : 'Внутреннее давление на уровне геометрического центра двери лестничной клетки i-го этажа'
                    }
                    onBlur={handleBlur}
                    fixValue={1}
                    disabled
                    size='large'
                  /> */}
                  <InputNumberForm
                    name='q'
                    addonBefore={<i>q =</i>}
                    label=''
                    onBlur={handleBlur}
                    disabled
                    size='large'
                  />
                  <InputNumberForm
                    name='Gs_n'
                    addonBefore={
                      currentView === 'Подземные' ? (
                        <i>
                          G<sub>s(-1)</sub> =
                        </i>
                      ) : systemTypeID === 3 ? (
                        <i>
                          G<sub>s1</sub> =
                        </i>
                      ) : (
                        <i>
                          G<sub>s2</sub> =
                        </i>
                      )
                    }
                    addonAfter={<i>кг/с</i>}
                    label='Массовый расход воздуха, поступающего через открытый дверной проем на этаже пожара'
                    fixValue={2}
                    onBlur={handleBlur}
                    disabled
                    size='large'
                  />
                </div>
              </div>
            )}

            <div>
              <Title level={5}>Расчётная часть</Title>

              <div className='grid grid-cols-2 gap-4 gap-y-8 py-4 items-end'>
                <InputNumberForm
                  name='Ps'
                  addonBefore={
                    currentView === 'Подземные' ? (
                      <i>
                        P<sub>s(-1)</sub> =
                      </i>
                    ) : systemTypeID === 3 ? (
                      <i>
                        P<sub>s1</sub> =
                      </i>
                    ) : (
                      <i>
                        P<sub>s2</sub> =
                      </i>
                    )
                  }
                  addonAfter={<i>Па</i>}
                  label={
                    currentView === 'Подземные'
                      ? 'Внутреннее давление на уровне геометрического центра двери лестничной клетки -1-го этажа'
                      : 'Внутреннее давление на уровне геометрического центра двери лестничной клетки i-го этажа'
                  }
                  onBlur={handleBlur}
                  fixValue={1}
                  disabled
                  size='large'
                />
                <InputNumberForm
                  name='Gs'
                  addonBefore={
                    currentView === 'Подземные' ? (
                      <i>
                        G<sub>s(-1)</sub> =
                      </i>
                    ) : systemTypeID === 3 ? (
                      <i>
                        G<sub>s1</sub> =
                      </i>
                    ) : (
                      <i>
                        G<sub>s</sub> =
                      </i>
                    )
                  }
                  addonAfter={<i>кг/с</i>}
                  label='Расчётный массовый расход воздуха'
                  fixValue={2}
                  onBlur={handleBlur}
                  disabled
                  size='large'
                />

                {isShowGsa && (
                  <InputNumberForm
                    name='Gsa'
                    addonBefore={
                      <i>
                        G<sub>sa</sub> =
                      </i>
                    }
                    addonAfter={<i>кг/с</i>}
                    label='Массовый расход воздуха, истекающий через наружный выход лестничной клетки'
                    fixValue={2}
                    onBlur={handleBlur}
                    disabled
                    size='large'
                  />
                )}
              </div>
              <div>
                <Button type='primary' onClick={handleOpenResultTable}>
                  Результирующая таблица
                </Button>
              </div>
            </div>

            <div>
              {/* <Title level={5}>Обвязка вентилятора</Title> */}
              <div className='grid grid-cols-2 gap-4 gap-y-8 py-4 items-end'>
                <InputNumberForm
                  name='Pds'
                  addonBefore={
                    <i>
                      P<sub>ds</sub> =
                    </i>
                  }
                  addonAfter={<i>Па</i>}
                  label='Суммарные потери давления в обвязке вентилятора при температуре ta'
                  onBlur={handleBlur}
                  size='large'
                />
              </div>
              <div className='grid grid-cols-2 gap-4 gap-y-8 py-4 items-end'>
                <InputNumberForm
                  name='Gsn'
                  addonBefore={
                    <i>
                      G<sub>sN</sub> =
                    </i>
                  }
                  addonAfter={<i>кг/с</i>}
                  disabled
                  label='Массовый расход воздуха, подаваемый в лестничную клетку'
                  fixValue={2}
                  onBlur={handleBlur}
                  size='large'
                />
                <InputNumberForm
                  name='PsN'
                  addonBefore={
                    <i>
                      P<sub>sN</sub> =
                    </i>
                  }
                  addonAfter={<i>Па</i>}
                  disabled
                  label={
                    currentView === 'Подземные'
                      ? 'Давление в уровне -N-го этажа лестничной клетки'
                      : 'Давление в оголовке лестничной клетки'
                  }
                  fixValue={1}
                  onBlur={handleBlur}
                  size='large'
                />
              </div>
            </div>

            <div>
              {/* <Title level={5}>Вентилятор</Title> */}

              <div className='grid grid-cols-2 gap-4 gap-y-8 py-4 items-end'>
                <InputNumberForm
                  name='hsN'
                  addonBefore={
                    <i>
                      h<sub>sN</sub> =
                    </i>
                  }
                  addonAfter={<i>м</i>}
                  label='Высота лестничной клетки или ее конструктивно выделенной зоны между уровнями нижнего и верхнего этажей'
                  onBlur={handleBlur}
                  size='large'
                />
                <InputNumberForm
                  name='h0s'
                  addonBefore={
                    <i>
                      h<sub>0s</sub> =
                    </i>
                  }
                  addonAfter={<i>м</i>}
                  label='Разность между уровнями расположения приемного устройства наружного воздуха и оголовка лестничной клетки или верхней части ее конструктивно выделенной зоны'
                  onBlur={handleBlur}
                  size='large'
                />
                <InputNumberForm
                  name='Lv'
                  addonBefore={
                    <i>
                      L<sub>v</sub> =
                    </i>
                  }
                  addonAfter={
                    <i>
                      м<sup>3</sup>/ч
                    </i>
                  }
                  disabled
                  label='Объемный расход воздуха, подаваемый в лестничную клетку'
                  onBlur={handleBlur}
                  size='large'
                />
                <InputNumberForm
                  name='Psv'
                  addonBefore={
                    <i>
                      P<sub>sv</sub> =
                    </i>
                  }
                  addonAfter={<i>Па</i>}
                  disabled
                  label='Приведенное статическое давление вентилятора'
                  onBlur={handleBlur}
                  size='large'
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
