import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { buildingObjectSelectors } from '@/core/redux/slices/smokeExtraction/buildingObject/selectors';
import { buildingObjectActions } from '@/core/redux/slices/smokeExtraction/buildingObject/slice';
import { calculationSelectors } from '@/core/redux/slices/smokeExtraction/calculation/selectors';
import { calculationActions } from '@/core/redux/slices/smokeExtraction/calculation/slice';

interface IUseCurrentCalculation {
  calculationID: number;
}

export const useCurrentCalculation = ({
  calculationID,
}: IUseCurrentCalculation) => {
  const currentCalculation = useAppSelector(calculationSelectors.calculation);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!calculationID) {
      return;
    }

    dispatch(
      calculationActions.fetchCalculation({
        calculationID: calculationID,
      })
    );
  }, [calculationID]);

  useEffect(() => {
    return () => {
      calculationActions.setCalculation(null);
    };
  }, []);

  return currentCalculation;
};
