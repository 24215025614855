import { Link } from 'react-router-dom';
import { FileDoneOutlined, FormOutlined } from '@ant-design/icons';
import { Button, Card, Typography } from 'antd';

import { ContentWrapper } from '@/components/ui/wrappers/ContentWrapper/ContentWrapper';
import { useAppDispatch } from '@/core/redux/hooks';
import { tourActions } from '@/core/redux/slices/tour/slice';

const { Text, Title, Paragraph } = Typography;

export const HomePage: React.FC = () => {
  const dispatch = useAppDispatch();

  return (
    <ContentWrapper>
      <div className='flex flex-col px-6 gap-2.5'>
        <div className='py-6'>
          <Title level={3}>Добро пожаловать в Аэропро.Эксперт!</Title>
          <Paragraph>
            Это сервис для расчёта вентиляционных систем. Это наш первый шаг на
            пути к созданию комплексного инструмента, который упростит вашу
            работу и повысит эффективность проектирования. Сейчас доступен
            базовый функционал для расчётов противодымных вентиляционных систем
            и аэродинамического расчёта.
          </Paragraph>
          <Paragraph>
            Мы создаем удобный и надежный инструмент для выполнения расчётов,
            соответствующих всем актуальным нормативам и стандартам. Развиваем и
            расширяем функционал нашего сервиса, добавляя функциональность и
            возможности, которые сделают Вашу работу еще более продуктивной и
            качественной. Мы уверены, что он станет незаменимым помощником в
            Вашей профессиональной деятельности, обеспечивая безопасность и
            комфорт в проектируемых зданиях. Ваше доверие и удобство работы с
            нашим сервисом будут ключевыми в его дальнейшем развитии и
            совершенствовании.
          </Paragraph>
          <Paragraph>
            Присоединяйтесь к нам и оцените возможности Аэропро.Эксперт!
          </Paragraph>
        </div>

        <div>
          <Card
            className='bg-primary-light border-primary'
            classNames={{
              body: '!p-4',
            }}
          >
            <div className='flex justify-between gap-4 items-center'>
              <div>
                <Text>
                  Откройте базовый функционал для расчетов аэродинамики и систем
                  ПДВ, начиная с пошаговой инструкции. <br /> Мы поможем вам на
                  каждом этапе, чтобы вы уверенно начали свой первый расчет
                  вместе с нами!
                </Text>
              </div>

              <Button
                onClick={() => dispatch(tourActions.startTour())}
                type='primary'
              >
                Начать сейчас
              </Button>
            </div>
          </Card>
        </div>

        <div className='flex gap-4 py-6'>
          <Card
            title={
              <div className='flex gap-2'>
                <FileDoneOutlined /> Консультация с экспертом
              </div>
            }
          >
            <Paragraph>
              Если у Вас возникли вопросы, связанные с расчётами в нашей
              программе, нормативным документам, вашими проектам или любые
              другие вопросы – получите консультацию эксперта в сфере ОВиК через
              нашего бота в Telegram. 
            </Paragraph>
            <div className='flex justify-end'>
              <Button
                type='primary'
                onClick={() => window.open('https://t.me/AeroPro_Expert_Bot')}
              >
                Чат с экспертом
              </Button>
            </div>
          </Card>
          <Card
            title={
              <div className='flex gap-2'>
                <FormOutlined /> Оставить отзыв
              </div>
            }
          >
            <Paragraph>
              Cервис находится в раннем доступе. Делитесь своими впечатлениями,
              сообщайте о проблемах, или предлагайте идеи по развитию сервиса,
              заполнив форму обратной связи. Мы постараемся учесть все
              комментарии в будущих обновлениях!
            </Paragraph>
            <div className='flex justify-end'>
              <Button
                type='primary'
                ghost
                onClick={() =>
                  window.open(
                    'https://forms.yandex.ru/cloud/66895defc417f32b335c12d2/'
                  )
                }
              >
                Оставить отзыв
              </Button>
            </div>
          </Card>
        </div>
      </div>
    </ContentWrapper>
  );
};
