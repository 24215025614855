import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.users;

const user = createSelector([selector], (state) => state.user);
const userLock = createSelector([selector], (state) => state.userLock);
const  updateUserLock = createSelector([selector], (state) => state.updateUserLock);


export const usersSelectors = { user, userLock, updateUserLock };
