import { Link } from 'react-router-dom';
import { Button, Typography } from 'antd';

import { ContentWrapper } from '@/components/ui/wrappers/ContentWrapper/ContentWrapper';

const { Title, Paragraph } = Typography;

export const ToolsPage: React.FC = () => {
  return (
    <ContentWrapper>
      <div className='p-6'>
        <Title level={3}>Подбор оборудования</Title>
        <Paragraph>
          Направьте нашим специалистам запрос на оперативный подбор
          вентиляционного оборудования. Получите результат в течение рабочего
          дня с гарантией точного соответствия подобранного оборудования
          требованиям и стандартам вашего проекта. Мы поможем вам выбрать лучшее
          оборудование и комплектующие для оптимальной производительности ваших
          систем.
        </Paragraph>

        <Paragraph>
          Услуга Подбор оборудования разработана специально для специалистов по
          проектированию ОВиК, чтобы сделать процесс выбора и интеграции
          оборудования максимально эффективным и точным. В рамках программы
          Аэропро.Эксперт мы предлагаем вам воспользоваться уникальными
          возможностями, обеспечивающими высочайшее качество и оперативность.{' '}
        </Paragraph>

        <Paragraph>
          Как это работает:
          <ol>
            <li>
              Запрос и анализ: Вы отправляете запрос на подбор вентиляционного
              оборудования нашим специалистам. При необходимости, наши
              специалисты связываются с вами для уточнения требований и
              стандартов вашего проекта.
            </li>
            <li>
              Консультация и подбор: Наши эксперты анализируют ваш запрос и
              подбирают оборудование, максимально соответствующее требованиям
              проекта
            </li>
            <li>
              Результат в течение рабочего дня: В 90% случаев, Вы получаете
              результат подбора в течение рабочего дня с гарантией точного
              соответствия подобранного оборудования всем стандартам и
              требованиям проекта.
            </li>
            <li>
              Поддержка и консультация: При необходимости, наши эксперты
              предоставят консультации по вопросам, связанным с расчетами и
              подбором оборудования, а также проконсультируют по вашему проекту.
            </li>
          </ol>
          Мы гарантируем оперативность, точность и полное соответствие всех
          решений вашим требованиям.
          Направьте нам запрос и убедитесь в преимуществах нашей программы уже
          сегодня!
        </Paragraph>
        <Button
          type='primary'
          onClick={() =>
            window.open(
              'https://forms.yandex.ru/cloud/667432dfc417f3423e33d716/'
            )
          }
        >
          Отправить запрос
        </Button>
      </div>
    </ContentWrapper>
  );
};
