import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/core/enums/loadingStatus';

export interface ICityParam {
  id: number;
  city: string;
  cold_temp: number;
  cold_speed: number;
  warm_temp: number;
  warm_speed: number;
  max_speed: number;
}

export interface IFetchCityParamsPayload {
  orderBy?: string;
  search?: string;
  page?: number;
  size?: number;
}

export interface ISystemParamsState {
  //city parametrs
  cityParams: ICityParam[] | null;
  cityParamsLock: LoadingStatus;
  //system types
  systemTypes: string[] | null;
  systemTypesLock: LoadingStatus;
}

const initialState: ISystemParamsState = {
  //city params
  cityParams: null,
  cityParamsLock: LoadingStatus.NEVER,
  //system types
  systemTypes: null,
  systemTypesLock: LoadingStatus.NEVER,
};

export const stystemParamsSlice = createSlice({
  name: 'stystemParams',
  initialState,
  reducers: {
    //city params
    fetchCityParams: (
      state,
      action: PayloadAction<IFetchCityParamsPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setCityParams: (state, action: PayloadAction<ICityParam[] | null>) => ({
      ...state,
      cityParams: action.payload,
    }),
    setCityParamsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      cityParamsLock: action.payload,
    }),
    //system types
    fetchSystemTypes: () => {},
    setSystemTypes: (state, action: PayloadAction<string[] | null>) => ({
      ...state,
      systemTypes: action.payload,
    }),
    setSystemTypesLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      systemTypesLock: action.payload,
    }),
  },
});

export const systemParamsReducer = stystemParamsSlice.reducer;
export const systemParamsActions = stystemParamsSlice.actions;
