import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, Input, Typography } from 'antd';
import * as yup from 'yup';

import { PasswordForm } from '@/components/form/Password';
import { useAppDispatch } from '@/core/redux/hooks';
import { authActions } from '@/core/redux/slices/auth/slice';

import { ProfileCard } from '../ProfileCard/ProfileCard';

const { Title, Text } = Typography;
interface ISecurity {
  login: string;
}

interface IChangePasswordForm {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const schema = yup.object().shape({
  currentPassword: yup.string().required(),
  newPassword: yup.string().min(8).required(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), ''], 'Passwords must match')
    .required(),
});

export const Security: React.FC<ISecurity> = ({ login }) => {
  const dispatch = useAppDispatch();

  const methods = useForm<IChangePasswordForm>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: IChangePasswordForm) => {
    dispatch(
      authActions.changePassword({
        currentPassword: data.currentPassword,
        newPassword: data.newPassword,
      })
    );
  };

  return (
    <div className='flex flex-col gap-6'>
      <ProfileCard>
        <div className='mb-2'>
          <Text>Ваш логин</Text>
        </div>
        <div className='grid grid-cols-2 gap-6 items-center'>
          <Input defaultValue={login} name='login' disabled />
          <Text className='text-success'>Почта подтверждена</Text>
        </div>
      </ProfileCard>
      <ProfileCard>
        <div className='flex flex-col gap-4'>
          <Title level={5}>Изменить пароль</Title>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div className='grid grid-cols-2 gap-6'>
                <div className='col-span-2'>
                  <PasswordForm
                    name='currentPassword'
                    label='Текущий пароль'
                    size='large'
                  />
                </div>
                <PasswordForm
                  name='newPassword'
                  label='Новый пароль'
                  note='Минимум 8 символов'
                  size='large'
                />
                <PasswordForm
                  name='confirmPassword'
                  label='Повторите пароль'
                  size='large'
                />
              </div>
              <div className='flex justify-end mt-12'>
                <Button type='primary' htmlType='submit' size='large'>
                  Изменить пароль
                </Button>
              </div>
            </form>
          </FormProvider>
        </div>
      </ProfileCard>
    </div>
  );
};
