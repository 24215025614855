import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IBuildingObjectModalPayload {
  personID: number;
  selectedPage: number;
}

export interface IModal {
  isRender: boolean;
  payload: IBuildingObjectModalPayload | null;
}

export interface IBuildingObjectModalsState {
  addBuildingObjectModal: IModal;
}

const initialState: IBuildingObjectModalsState = {
  addBuildingObjectModal: {
    isRender: false,
    payload: null,
  },
};

export const buildingObjectModalsSlice = createSlice({
  name: 'buildingObjectModal',
  initialState,
  reducers: {
    openAddBuildingObjectModal: (
      state,
      action: PayloadAction<IBuildingObjectModalPayload | null>
    ) => ({
      ...state,
      addBuildingObjectModal: {
        isRender: true,
        payload: action.payload,
      },
    }),
    closeAddBuildingObjectModal: (state) => ({
      ...state,
      addBuildingObjectModal: {
        isRender: false,
        payload: null,
      },
    }),
  },
});

export const buildingObjectModalsReducer = buildingObjectModalsSlice.reducer;
export const buildingObjectModalsActions = buildingObjectModalsSlice.actions;
