import { Typography } from 'antd';

import { InputNumberForm } from '@/components/form/InputNumber';

const { Text, Title } = Typography;

interface IUndergroundPart {
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
}

export const UndergroundPart: React.FC<IUndergroundPart> = ({ handleBlur }) => {
  return (
    <div>
      <Title level={5}>Подземная часть</Title>
      <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
        <InputNumberForm
          addonBefore={
            <i>
              t<sub>r</sub> =
            </i>
          }
          addonAfter={<i>°C</i>}
          name='trpodz'
          label='Температура воздуха внутри здания'
          placeholder='Введите значение'
          onBlur={handleBlur}
          size='large'
        />
        <div></div>
        <InputNumberForm
          addonBefore={
            <i>
              T<sub>r</sub> =
            </i>
          }
          addonAfter={<i>°К</i>}
          name='Trpodz'
          label='Температура воздуха внутри здания'
          placeholder='Введите значение'
          disabled
          onBlur={handleBlur}
          size='large'
        />
        <InputNumberForm
          addonBefore={
            <i>
              ρ<sub>r</sub> =
            </i>
          }
          addonAfter={
            <i>
              кг/м<sup>3</sup>
            </i>
          }
          name='prpodz'
          label='Плотность воздуха внутри здания'
          placeholder='Введите значение'
          onBlur={handleBlur}
          disabled
          size='large'
        />
        <InputNumberForm
          addonBefore={
            <i>
              T<sub>l</sub> =
            </i>
          }
          addonAfter={<i>°К</i>}
          name='Tlpodz'
          label='Температура воздуха в защищаемых лестнично-лифтовых узлах'
          placeholder='Введите значение'
          onBlur={handleBlur}
          disabled
          size='large'
        />
        <InputNumberForm
          addonBefore={
            <i>
              ρ<sub>l</sub> =
            </i>
          }
          addonAfter={
            <i>
              кг/м<sup>3</sup>
            </i>
          }
          name='plpodz'
          label='Плотность воздуха в защищаемых лестничных узлах'
          placeholder='Введите значение'
          disabled
          onBlur={handleBlur}
          size='large'
        />
        <InputNumberForm
          addonBefore={
            <i>
              S<sub>dl</sub> =
            </i>
          }
          addonAfter={
            <i>
              м<sup>3</sup>/кг
            </i>
          }
          name='Sdlpodz'
          label='Удельное сопротивление воздухопроницанию дверей лифтовой шахты'
          placeholder='Введите значение'
          fixValue={0}
          disabled
          onBlur={handleBlur}
          size='large'
        />
      </div>
      <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
        <InputNumberForm
          addonBefore={<i>N =</i>}
          name='Npodz'
          label='Последний подземный этаж (максимум -5)'
          placeholder='Введите значение'
          onBlur={handleBlur}
          size='large'
        />
        <InputNumberForm
          addonBefore={
            <i>
              h<sub>-n</sub> =
            </i>
          }
          name='h_n'
          addonAfter={<i>м</i>}
          label='Глубина подземной части или уровень отметки пола нижнего подземного этажа (отрицательная величина)'
          placeholder='Введите значение'
          onBlur={handleBlur}
          size='large'
        />
        <InputNumberForm
          addonBefore={
            <i>
              h<sub>тэ</sub> =
            </i>
          }
          name='htepodz'
          addonAfter={<i>м</i>}
          label='Высота типового этажа'
          placeholder='Введите значение'
          onBlur={handleBlur}
          size='large'
        />
      </div>
    </div>
  );
};
