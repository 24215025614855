import { Typography } from 'antd';

import { InputNumberForm } from '@/components/form/InputNumber';
import { RadioGroupForm } from '@/components/form/Radio';
import { useAppSelector } from '@/core/redux/hooks';
import { systemSelectors } from '@/core/redux/slices/smokeExtraction/system/selectors';

import { DynamicRoomOpeningTable } from '../../DynamicRoomOpeningTable/DynamicRoomOpeningTable';

interface IRoomOpening {
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  handleChange: () => void;
}

interface IFireType {
  title: string;
  desc: string;
}

const { Text, Title } = Typography;

export const RoomOpening: React.FC<IRoomOpening> = ({
  handleBlur,
  handleChange,
}) => {
  const systemState = useAppSelector(systemSelectors.systemState);

  const buildingTypeOptions = [
    {
      label: 'Жилое',
      value: 'Жилое',
    },
    {
      label: 'Общественное',
      value: 'Общественное',
    },
  ];

  const fireTypes: Record<string, IFireType> = {
    ПРН: {
      title: 'ПРН',
      desc: `пожар, регулируемый нагрузкой. Горение происходит при
          достаточном количестве воздуха (окислителя) и скорость выгорания
          (тепловыделения) пожарной нагрузки главным образом зависит от вида,
          расположения и количества пожарной нагрузки, в меньшей степени - от
          характера газообмена очага пожара с окружающей средой, и
          пропорциональна площади горения`,
    },
    ПРВ: {
      title: 'ПРВ',
      desc: `пожар, регулируемый вентиляцией. Горение пожарной нагрузки происходит при недостатке воздуха, а скорость выгорания пропорциональна количеству поступающего в объем помещения окислителя. При недостатке окислителя в очаге пожара возможно догорание продуктов горения за пределами помещения.`,
    },
  };

  const currentFireType: IFireType = fireTypes[systemState?.fire_type];

  return (
    <div>
      <Title level={5}>Проемность помещения</Title>

      <div className='py-8'>
        <DynamicRoomOpeningTable
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      </div>

      <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
        <InputNumberForm
          name='A0'
          addonBefore={
            <i>
              A<sub>0</sub> =
            </i>
          }
          addonAfter={
            <i>
              м<sup>2</sup>
            </i>
          }
          label='Суммарная площадь проемов горящего помещения'
          disabled
          onBlur={handleBlur}
          fixValue={2}
          size='large'
        />

        <InputNumberForm
          name='Qpnd'
          addonBefore={
            <i>
              Q<sup>p</sup>
              <sub>нд</sub> =
            </i>
          }
          addonAfter={<i>МДж/кг</i>}
          label='Низшая теплота сгорания древесины'
          disabled
          onBlur={handleBlur}
          size='large'
        />
        <InputNumberForm
          name='gk'
          addonBefore={
            <i>
              g<sub>k</sub> =
            </i>
          }
          addonAfter={
            <i>
              кг/м<sup>2</sup>
            </i>
          }
          label='Удельная приведенная пожарная нагрузка, отнесенная к площади тепловоспринимающей поверхности ограждающих строительных конструкций помещения'
          disabled
          onBlur={handleBlur}
          fixValue={1}
          size='large'
        />
        <InputNumberForm
          name='Qpns'
          addonBefore={
            <i>
              Q<sup>p</sup>
              <sub>нср</sub> =
            </i>
          }
          addonAfter={<i>МДж/кг</i>}
          label='Средняя теплота сгорания пожарной нагрузки'
          disabled
          onBlur={handleBlur}
          fixValue={1}
          size='large'
        />
        <InputNumberForm
          name='V0'
          addonBefore={
            <i>
              V<sub>0</sub> =
            </i>
          }
          addonAfter={
            <i>
              м<sup>3</sup>/кг
            </i>
          }
          label='Удельное количество воздуха, необходимое для полного сгорания пожарной нагрузки помещения'
          disabled
          onBlur={handleBlur}
          fixValue={1}
          size='large'
        />
        <InputNumberForm
          name='P'
          addonBefore={<i>П =</i>}
          addonAfter={
            <i>
              м<sup>1/2</sup>
            </i>
          }
          label='Проемность помещения'
          disabled
          onBlur={handleBlur}
          fixValue={2}
          size='large'
        />
        <InputNumberForm
          name='gkkp'
          addonBefore={
            <i>
              g<sub>k kp</sub> =
            </i>
          }
          addonAfter={
            <i>
              кг/м<sup>2</sup>
            </i>
          }
          label='Удельное критическое количество пожарной нагрузки'
          disabled
          onBlur={handleBlur}
          fixValue={1}
          size='large'
        />
      </div>
      <div className='mt-4 mb-8'>
        <Text className='block' type='secondary'>
          Тип пожара
        </Text>

        {currentFireType ? (
          <Text>
            <b>{currentFireType.title}</b> - {currentFireType.desc}
          </Text>
        ) : '--'}
      </div>
      <div className='grid grid-cols-2 gap-4 gap-y-8 items-end mb-8'>
        <InputNumberForm
          name='g0'
          addonBefore={
            <i>
              g<sub>0</sub> =
            </i>
          }
          addonAfter={
            <i>
              кг/м<sup>2</sup>
            </i>
          }
          label='Удельная приведенная пожарная нагрузка, отнесенная к площади пола помещения'
          disabled
          onBlur={handleBlur}
          fixValue={1}
          size='large'
        />
        <div></div>
        <InputNumberForm
          name='tr'
          addonBefore={
            <i>
              t<sub>r</sub> =
            </i>
          }
          addonAfter={<i>°C</i>}
          label='Начальная температура воздуха в помещении, °C '
          onBlur={handleBlur}
          size='large'
        />
        <InputNumberForm
          name='Tr'
          addonBefore={
            <i>
              T<sub>r</sub> =
            </i>
          }
          addonAfter={<i>°К</i>}
          label='Начальная температура воздуха в помещении'
          disabled
          onBlur={handleBlur}
          size='large'
        />
        <InputNumberForm
          name='T0max'
          addonBefore={
            <i>
              T<sub>0 max</sub> =
            </i>
          }
          addonAfter={<i>°К</i>}
          label='Максимальная среднеобъемная температура в горящем помещении'
          disabled
          fixValue={1}
          onBlur={handleBlur}
          size='large'
        />
        <InputNumberForm
          name='T0'
          addonBefore={
            <i>
              T<sub>0</sub> =
            </i>
          }
          addonAfter={<i>°К</i>}
          label='Искомое значение температуры газов, поступающих из горящего помещения в смежное (коридор)'
          disabled
          fixValue={1}
          onBlur={handleBlur}
          size='large'
        />
        <InputNumberForm
          name='H'
          addonBefore={<i>H =</i>}
          addonAfter={<i>м</i>}
          label='Высота смежного помещения (коридора)'
          onBlur={handleBlur}
          size='large'
        />
        <InputNumberForm
          name='Ac'
          addonBefore={
            <i>
              A<sub>c</sub> =
            </i>
          }
          addonAfter={
            <i>
              м<sup>2</sup>
            </i>
          }
          label='Площадь смежного помещения (коридора)'
          onBlur={handleBlur}
          size='large'
        />
        <InputNumberForm
          name='lc'
          addonBefore={
            <i>
              l<sub>c</sub> =
            </i>
          }
          addonAfter={<i>м</i>}
          label='Длина смежного помещения (коридора)'
          onBlur={handleBlur}
          size='large'
        />
        <InputNumberForm
          name='hsmH'
          addonBefore={
            <i>
              h<sub>sm</sub>/H =
            </i>
          }
          label='Соотношение толщины дымового слоя и высоты смежного помещения (коридора)'
          onBlur={handleBlur}
          note='В диапозоне 0.5 - 0.6 согласно МД.137-13'
          size='large'
        />
        <InputNumberForm
          name='hsm'
          addonBefore={
            <i>
              h<sub>sm</sub> =
            </i>
          }
          addonAfter={<i>м</i>}
          label='Предельная толщина дымового слоя'
          disabled
          onBlur={handleBlur}
          fixValue={2}
          size='large'
        />
      </div>
      <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
        <InputNumberForm
          name='trk'
          addonBefore={
            <i>
              t<sub>r k</sub> =
            </i>
          }
          addonAfter={<i>°C</i>}
          label='Температура воздуха в смежном помещении (коридоре)'
          onBlur={handleBlur}
          size='large'
        />
        <InputNumberForm
          name='Trk'
          addonBefore={
            <i>
              T<sub>r k</sub> =
            </i>
          }
          addonAfter={<i>°К</i>}
          label='Температура воздуха в смежном помещении (коридоре) '
          disabled
          onBlur={handleBlur}
          size='large'
        />
        <InputNumberForm
          name='Tsm'
          addonBefore={
            <i>
              T<sub>sm</sub> =
            </i>
          }
          addonAfter={<i>°К</i>}
          label='Усредненная температура дымового слоя в смежном помещении (коридоре)'
          disabled
          onBlur={handleBlur}
          fixValue={1}
          size='large'
        />
        <InputNumberForm
          name='tsm'
          addonBefore={
            <i>
              t<sub>sm</sub> =
            </i>
          }
          addonAfter={<i>°C</i>}
          label='Усредненная температура дымового слоя в смежном помещении (коридоре)'
          disabled
          onBlur={handleBlur}
          fixValue={1}
          size='large'
        />
        <InputNumberForm
          name='psm'
          addonBefore={
            <i>
              ρ<sub>sm</sub>=
            </i>
          }
          addonAfter={<i>°C</i>}
          label='Объемный расход удаления из смежного помещения (коридора) продуктов горения'
          disabled
          onBlur={handleBlur}
          fixValue={1}
          size='large'
        />
      </div>
      <div className='py-8'>
        <RadioGroupForm
          name='building_type'
          defaultValue='Жилое'
          label='Тип здания'
          optionType='button'
          options={buildingTypeOptions}
          onChange={handleChange}
        />
      </div>
      <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
        <InputNumberForm
          name='ksm'
          addonBefore={
            <i>
              k<sub>sm</sub> =
            </i>
          }
          label='Поправочный коэффициент на тип здания'
          disabled
          onBlur={handleBlur}
          size='large'
        />
        <div></div>
        <InputNumberForm
          name='ad'
          addonBefore={
            <i>
              a<sub>d</sub> =
            </i>
          }
          addonAfter={<i>м</i>}
          label='Ширина двери при выходе из смежного помещения (коридора) по путям эвакуации'
          onBlur={handleBlur}
          size='large'
        />
        <InputNumberForm
          name='Hd'
          addonBefore={
            <i>
              H<sub>d</sub> =
            </i>
          }
          label='Высота двери при выходе из смежного помещения (коридора) по путям эвакуации'
          onBlur={handleBlur}
          size='large'
        />
        <InputNumberForm
          name='Ad'
          addonBefore={
            <i>
              A<sub>d</sub> =
            </i>
          }
          addonAfter={
            <i>
              м<sup>2</sup>
            </i>
          }
          label='Площадь двери при выходе из смежного помещения (коридора) по путям эвакуации'
          onBlur={handleBlur}
          fixValue={2}
          disabled
          size='large'
        />
        <InputNumberForm
          name='Gsm'
          addonBefore={
            <i>
              G<sub>sm</sub> =
            </i>
          }
          addonAfter={<i>кг/с</i>}
          label='Массовый расход удаляемых из смежного помещения (коридора) продуктов горения'
          onBlur={handleBlur}
          fixValue={2}
          disabled
          size='large'
        />
        <InputNumberForm
          name='Lsm'
          addonBefore={
            <i>
              L<sub>sm</sub> =
            </i>
          }
          addonAfter={
            <i>
              м<sup>3</sup>/ч
            </i>
          }
          label='Объёмный расход удаляемых из смежного помещения (коридора) продуктов горения'
          onBlur={handleBlur}
          disabled
          size='large'
        />
      </div>
    </div>
  );
};
