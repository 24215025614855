import { Typography } from 'antd';

import { InputNumberForm } from '@/components/form/InputNumber';

const { Text, Title } = Typography;

interface IAboveGround {
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
}

export const AboveGround: React.FC<IAboveGround> = ({ handleBlur }) => {
  return (
    <div>
      <Title level={5}>Параметры надземной части</Title>
      <div className='flex flex-col gap-8'>
        <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
          <InputNumberForm
            addonBefore={
              <i>
                t<sub>r</sub> =
              </i>
            }
            addonAfter={<i>°C</i>}
            name='trnadz'
            label='Температура воздуха внутри здания'
            placeholder='Введите значение'
            onBlur={handleBlur}
            size='large'
          />
          <div></div>
          <InputNumberForm
            addonBefore={
              <i>
                T<sub>r</sub> =
              </i>
            }
            addonAfter={<i>°К</i>}
            name='Trnadz'
            label='Температура воздуха внутри здания'
            placeholder='Введите значение'
            onBlur={handleBlur}
            disabled
            size='large'
          />
          <InputNumberForm
            addonBefore={
              <i>
                ρ<sub>r</sub> =
              </i>
            }
            addonAfter={
              <i>
                кг/м<sup>3</sup>
              </i>
            }
            name='prnadz'
            label='Плотность воздуха внутри здания'
            placeholder='Введите значение'
            onBlur={handleBlur}
            disabled
            size='large'
          />
          <InputNumberForm
            addonBefore={
              <i>
                T<sub>l</sub> =
              </i>
            }
            addonAfter={<i>°К</i>}
            name='Tlnadz'
            label='Температура воздуха в защищаемых лестнично-лифтовых узлах'
            placeholder='Введите значение'
            onBlur={handleBlur}
            disabled
            size='large'
          />
          <InputNumberForm
            addonBefore={
              <i>
                ρ<sub>l</sub> =
              </i>
            }
            addonAfter={
              <i>
                кг/м<sup>3</sup>
              </i>
            }
            name='plnadz'
            label='Плотность воздуха в защищаемых лестничных узлах'
            placeholder='Введите значение'
            onBlur={handleBlur}
            disabled
            size='large'
          />
          <InputNumberForm
            addonBefore={
              <i>
                S<sub>dl</sub> =
              </i>
            }
            addonAfter={
              <i>
                м<sup>3</sup>/кг
              </i>
            }
            name='Sdlnadz'
            disabled
            label='Удельное сопротивление воздухопроницанию дверей лифтовой шахты'
            placeholder='Введите значение'
            fixValue={0}
            onBlur={handleBlur}
            size='large'
          />
        </div>
        <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
          <InputNumberForm
            addonBefore={<i>N =</i>}
            name='Nnadz'
            label='Количество этажей'
            placeholder='Введите значение'
            onBlur={handleBlur}
            size='large'
          />
          <InputNumberForm
            addonBefore={
              <i>
                h<sub>2</sub> =
              </i>
            }
            name='h2'
            addonAfter={<i>м</i>}
            label='Отметка пола 2-го этажа'
            placeholder='Введите значение'
            onBlur={handleBlur}
            size='large'
          />
          <InputNumberForm
            addonBefore={
              <i>
                h<sub>тэ</sub> =
              </i>
            }
            name='htenadz'
            addonAfter={<i>м</i>}
            label='Высота типового этажа'
            placeholder='Введите значение'
            onBlur={handleBlur}
            size='large'
          />
        </div>
      </div>
    </div>
  );
};
