import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IResult {
  i: number | string;
  h: number | string | null;
  Ps: number | string | null;
  vs: number | string | null;
  Gs: number | string | null;
  dGsd: number | string | null;
  dGsw: number | string | null;
  dGs: number | string | null;
}

export interface IElevartorShaftResult {
  i: number | string;
  h: number | string | null;
  Pl: number | string | null;
  sum_delta_Gl: number | string | null;
}
export interface IDisplayResultModalPayload {
  resultTableData: IResult[] | [];
  systemTypeID: number;
  currentView: string;
}

export interface IElevatorShaftResultModalPayload {
  resultTableData: IElevartorShaftResult[] | [];
}

export interface IElevatorShaftResultModal {
  isRender: boolean;
  payload: IElevatorShaftResultModalPayload | null;
}

export interface IModal {
  isRender: boolean;
  payload: IDisplayResultModalPayload | null;
}

export interface ISystemModalsState {
  displayResultModal: IModal;
  elevatorShaftsResultModal: IElevatorShaftResultModal;
}

const initialState: ISystemModalsState = {
  displayResultModal: {
    isRender: false,
    payload: null,
  },
  elevatorShaftsResultModal: {
    isRender: false,
    payload: null,
  },
};

export const systemModalsSlice = createSlice({
  name: 'systemModals',
  initialState,
  reducers: {
    //Smoke free stairwells modal
    openDiplayResultModal: (
      state,
      action: PayloadAction<IDisplayResultModalPayload | null>
    ) => ({
      ...state,
      displayResultModal: {
        isRender: true,
        payload: action.payload,
      },
    }),
    closeDisplayResultModal: (state) => ({
      ...state,
      displayResultModal: {
        isRender: false,
        payload: null,
      },
    }),
    //Elevator shafts modal
    openElevatorShaftsResultModal: (
      state,
      action: PayloadAction<IElevatorShaftResultModalPayload | null>
    ) => ({
      ...state,
      elevatorShaftsResultModal: {
        isRender: true,
        payload: action.payload,
      },
    }),
    closeElevatorShaftsResultModal: (state) => ({
      ...state,
      elevatorShaftsResultModal: {
        isRender: false,
        payload: null,
      },
    }),
  },
});

export const systemnModalsReducer = systemModalsSlice.reducer;
export const systemModalsActions = systemModalsSlice.actions;
