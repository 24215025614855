import { Typography } from 'antd';

import { InputNumberForm } from '@/components/form/InputNumber';

const { Text, Title } = Typography;

interface IElevatorShaft {
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
}

export const ElevatorShaft: React.FC<IElevatorShaft> = ({ handleBlur }) => {
  return (
    <div>
      <Title level={5}>Параметры лифтовой шахты</Title>
      <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
        <InputNumberForm
          addonBefore={
            <i>
              F<sub>lc</sub> =
            </i>
          }
          addonAfter={
            <i>
              м<sup>2</sup>
            </i>
          }
          name='Flc'
          label='Площадь поперечного сечения кабины лифта (по внешнему контуру ограждений кабины)'
          placeholder='Введите значение'
          onBlur={handleBlur}
          size='large'
        />
        <InputNumberForm
          addonBefore={
            <i>
              F<sub>ls</sub> =
            </i>
          }
          addonAfter={
            <i>
              м<sup>2</sup>
            </i>
          }
          name='Fls'
          label='Площадь поперечного сечения лифтовой шахты (по внутреннему контуру ограждений)'
          placeholder='Введите значение'
          onBlur={handleBlur}
          size='large'
        />
        <InputNumberForm
          addonBefore={
            <i>
              ξ<sub>l</sub> =
            </i>
          }
          name='El'
          label='Коэффициент местного сопротивления узла "кабина-шахта" при открытых дверях кабины и шахты на основном посадочном этаже'
          placeholder='Введите значение'
          onBlur={handleBlur}
          fixValue={2}
          disabled
          size='large'
        />
      </div>
    </div>
  );
};
