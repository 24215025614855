import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { LoadingStatus } from '@/core/enums/loadingStatus';
import type oas from '@/services/rest/base/openapi';

import { usersSelectors } from './selectors';
import { IUpdateUserPayload, usersActions } from './slice';

type UserResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/users/me',
  'get',
  '200'
>;

type UpdateUserRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/users/me',
  'put'
>;

function* fetchUser(): Generator<any, void, UserResponse> {
  yield put(usersActions.setUserLock(LoadingStatus.LOADING));

  try {
    const response = yield call(restCall, '/users/me', 'get');

    yield put(usersActions.setUser(response));
    yield put(usersActions.setUserLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on user fetching:', error);
    yield put(usersActions.setUserLock(LoadingStatus.ERROR));
  }
}

function* updateUser(
  action: PayloadAction<IUpdateUserPayload>
): Generator<any, void, any> {
  const userData = yield select(usersSelectors.user);
  const payloadData = action.payload;

  yield put(usersActions.setUpdateUserLock(LoadingStatus.LOADING));

  const request: UpdateUserRequest = {
    json: {
      ...userData,
      ...payloadData,
    },
    ...authAdd(),
  };

  try {
    const response = yield call(restCall, '/users/me', 'put', request);

    yield put(usersActions.setUser(response));

    yield put(usersActions.setUpdateUserLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on user update:', error);

    yield put(usersActions.setUpdateUserLock(LoadingStatus.ERROR));
  }
}

export const usersSagas = [
  takeLatest(usersActions.fetchUser, fetchUser),
  takeLatest(usersActions.updateUser, updateUser),
];
