import { AddBuildingObjectModal } from '@/modals/modals/AddBuildingObjectModal/AddBuildingObjectModal';
import { AddCalculationModal } from '@/modals/modals/AddCalculationModal/AddCalculationModal';
import { DisplayResultModal } from '@/modals/modals/DisplayResultModal/DisplayResultModal';

import { ElevatorShaftsResultModal } from './modals/ElevatorShaftsResultModal/ElevatorShaftsResultModal';

export const ModalRoot: React.FC = () => {
  return (
    <>
      <AddBuildingObjectModal />
      <AddCalculationModal />
      <DisplayResultModal />
      <ElevatorShaftsResultModal />
    </>
  );
};
