import { useState } from 'react';
import { Tabs, TabsProps, Typography } from 'antd';

import { useAppSelector } from '@/core/redux/hooks';
import { usersSelectors } from '@/core/redux/slices/users/selectors';
import User from '@/images/user.svg';

import { CompanyInfo } from './CompanyInfo/CmpanyInfo';
import { Security } from './Security/Security';
import { UserInfo } from './UserInfo/UserInfo';

const { Text, Title } = Typography;

enum ProfilePageViews {
  UserInfo = 'user',
  CompanyInfo = 'company',
  Security = 'security',
}
interface View {
  key: ProfilePageViews;
  view: JSX.Element;
}

export const ProfilePage: React.FC = () => {
  const user = useAppSelector(usersSelectors.user);

  const [selectedView, setSelectedView] = useState<ProfilePageViews>(
    ProfilePageViews.UserInfo
  );

  const profileTabs: TabsProps['items'] = [
    {
      key: ProfilePageViews.UserInfo,
      label: 'Данные пользователя',
    },
    {
      key: ProfilePageViews.CompanyInfo,
      label: 'Моя организация',
    },
    {
      key: ProfilePageViews.Security,
      label: 'Безопасность',
    },
  ];

  const provileViews: Record<ProfilePageViews, View> = {
    [ProfilePageViews.UserInfo]: {
      key: ProfilePageViews.UserInfo,
      view: (
        <UserInfo
          name={user?.name ?? ''}
          phone={user?.phone ?? ''}
          city={user?.city ?? ''}
        />
      ),
    },
    [ProfilePageViews.CompanyInfo]: {
      key: ProfilePageViews.CompanyInfo,
      view: (
        <CompanyInfo
          companyName={user?.company_name ?? ''}
          jobTitle={user?.job_title ?? ''}
          inn={user?.inn || ''}
          orgSite={user?.org_site ?? ''}
        />
      ),
    },
    [ProfilePageViews.Security]: {
      key: ProfilePageViews.Security,
      view: <Security login={user?.email ?? ''} />,
    },
  };

  const onTabChange = (key: string) => {
    setSelectedView(key as ProfilePageViews);
  };

  return (
    <div className='h-full overflow-hidden flex flex-col'>
      <div className='bg-white'>
        <div className='p-6 flex flex-col gap-2'>
          <div className='flex gap-6 items-center'>
            <img
              src={User}
              alt='Аэропро.Эксперт'
              className='w-[72px] h-[72px]'
            />
            <div className='flex flex-col'>
              <div className='flex gap-2'>
                <Title level={5} className='!mb-0'>
                  {user?.name}
                </Title>
                <Text type='secondary'>/</Text>
                <Text type='secondary'>Данные пользователя</Text>
              </div>
              <div>
                <Text type='secondary'>
                  Настройте ваш профиль в Аэропро.Эксперт
                </Text>
              </div>
            </div>
          </div>
        </div>
        <div className='px-6'>
          <Tabs
            className='-mb-4'
            defaultActiveKey='user'
            items={profileTabs}
            onChange={onTabChange}
          />
        </div>
      </div>
      <div className='p-6 grow h-full overflow-auto'>
        {provileViews[selectedView].view}
      </div>
    </div>
  );
};
