import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { LoadingStatus } from '@/core/enums/loadingStatus';
import type oas from '@/services/rest/base/openapi';

import { IFetchCityParamsPayload, systemParamsActions } from './slice';

type CityParamsResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/climatology',
  'get',
  '200'
>;

type SystemTypesResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/system_types',
  'get',
  '200'
>;

function* fetchCityParams(
  action: PayloadAction<IFetchCityParamsPayload>
): Generator<any, void, CityParamsResponse> {
  yield put(systemParamsActions.setCityParams([]));
  yield put(systemParamsActions.setCityParamsLock(LoadingStatus.LOADING));

  const { search, page, size } = action.payload;

  try {
    const response = yield call(
      restCall,
      '/smoke_extraction/climatology',
      'get',
      {
        query: {
          page: page,
          search: search,
          size: size,
        },
        ...authAdd(),
      }
    );

    const cityParams: CityParamsResponse = response;

    yield put(systemParamsActions.setCityParams(cityParams.items));

    yield put(systemParamsActions.setCityParamsLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on city params fetching');
    yield put(systemParamsActions.setCityParamsLock(LoadingStatus.ERROR));
  }
}

function* fetchSystemTypes(): Generator<any, void, SystemTypesResponse> {
  yield put(systemParamsActions.setSystemTypesLock(LoadingStatus.LOADING));

  try {
    const response = yield call(
      restCall,
      '/smoke_extraction/system_types',
      'get',
      {
        ...authAdd(),
      }
    );

    const systemTypes: string[] = response;

    yield put(systemParamsActions.setSystemTypes(systemTypes));

    yield put(systemParamsActions.setSystemTypesLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on systems types fetching');
    yield put(systemParamsActions.setSystemTypesLock(LoadingStatus.ERROR));
  }
}

export const systemParamsSagas = [
  takeLatest(systemParamsActions.fetchCityParams, fetchCityParams),
  takeLatest(systemParamsActions.fetchSystemTypes, fetchSystemTypes),
];
