import { Link } from 'react-router-dom';
import { Button, Typography } from 'antd';

import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { authActions } from '@/core/redux/slices/auth/slice';
import { usersSelectors } from '@/core/redux/slices/users/selectors';
import Logo from '@/images/logo-light.svg';
import User from '@/images/user.svg';

export const Header: React.FC = () => {
  const user = useAppSelector(usersSelectors.user);
  const dispatch = useAppDispatch();

  const { Text } = Typography;

  const onLogOut = () => {
    dispatch(authActions.logout());
  };

  return (
    <div className='bg-primary-dark h-12 flex justify-between items-center px-4'>
      <img src={Logo} alt='Аэропро.Эксперт' />
      {user && (
        <div className='px-3 flex gap-2 items-center'>
          <Link
            to={{
              pathname: '/profile',
            }}
          >
            <img src={User} alt='Аэропро.Эксперт' />
          </Link>

          <Text className='text-white'>{user.name}</Text>
          <Button type='primary' onClick={onLogOut}>
            Выйти
          </Button>
        </div>
      )}
    </div>
  );
};
