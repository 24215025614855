import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, Tabs, Typography } from 'antd';
import * as yup from 'yup';

import { InputForm } from '@/components/form/Input';
import { LoadingStatus } from '@/core/enums/loadingStatus';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { authSelectors } from '@/core/redux/slices/auth/selectors';
import { authActions } from '@/core/redux/slices/auth/slice';

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Неправильный формат почты')
    .required('Поле обязательно для заполнения'),
});

interface IResetPasswordForm {
  email: string;
}

export const ResetPasswordPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const forgotPasswordLock = useAppSelector(authSelectors.forgotPasswordLock);

  const methods = useForm<IResetPasswordForm>({
    resolver: yupResolver(schema),
  });
  const { Text, Title } = Typography;

  const onSubmit = (data: IResetPasswordForm) => {
    dispatch(
      authActions.forgotPassword({
        email: data.email,
      })
    );
  };
  useEffect(() => {
    if (forgotPasswordLock === LoadingStatus.LOADED) {
      navigate('/auth/reset/confirm');
    }
  }, [forgotPasswordLock]);

  return (
    <Card
      style={{
        width: 368,
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className='flex flex-col gap-2 mb-6'>
            <Title level={4}>Восстановление пароля</Title>
            <Text>
              Введите email, который вы использовали <br /> при регистрации.
            </Text>
          </div>

          <div className='flex flex-col gap-6'>
            <div className='flex flex-col gap-2'>
              <InputForm name='email' placeholder='E-mail' size='large' />
            </div>
            <div>
              <Button
                htmlType='submit'
                type='primary'
                block
                className='mb-2'
                loading={forgotPasswordLock === LoadingStatus.LOADING}
              >
                Восстановить
              </Button>
            </div>
            <div className='flex gap-2'>
              <Button
                type='link'
                onClick={() => navigate('/auth/signup')}
                className='w-full'
              >
                Регистрация
              </Button>
              <Button
                type='link'
                onClick={() => navigate('/auth/login')}
                className='w-full'
              >
                Войти
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </Card>
  );
};
