import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/core/enums/loadingStatus';

export interface IUser {
  name?: string | null;
  phone?: string | null;
  company_name?: string | null;
  email: string;
  uuid: string;
  password?: string;
  is_onboarded?: boolean;
  city?: string | null;
  inn?: string | null;
  org_site?: string | null;
  job_title?: string | null;
  is_active?: boolean;
}

export interface IUpdateUserPayload {
  phone?: string;
  name?: string;
  company_name?: string;
  city?: string;
  inn?: string | null;
  org_site?: string;
  job_title?: string;
}

export interface IUsersState {
  user: IUser | null;
  userLock: LoadingStatus;
  updateUserLock: LoadingStatus;
}

const initialState: IUsersState = {
  user: null,
  userLock: LoadingStatus.NEVER,
  updateUserLock: LoadingStatus.NEVER
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    fetchUser: () => {},
    setUser: (state, action: PayloadAction<IUser | null>) => ({
      ...state,
      user: action.payload,
    }),
    setUserLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      userLock: action.payload,
    }),
    updateUser: (state, action: PayloadAction<IUpdateUserPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setUpdateUserLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      updateUserLock: action.payload,
    }),
  },
});

export const usersReducer = usersSlice.reducer;
export const usersActions = usersSlice.actions;
