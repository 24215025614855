import { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  CheckOutlined,
  DownOutlined,
  EditOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import {
  Button,
  Divider,
  Dropdown,
  Input,
  InputRef,
  MenuProps,
  Tooltip,
  Typography,
} from 'antd';
import { MD5 } from 'crypto-js';
import { twMerge } from 'tailwind-merge';

import { Breadcrumbs } from '@/components/ui/Breadcrumbs/Breadcrumbs';
import { TourItem } from '@/components/ui/TourItem/TourItem';
import { ContentWrapper } from '@/components/ui/wrappers/ContentWrapper/ContentWrapper';
import { LoadingStatus } from '@/core/enums/loadingStatus';
import { SystemType } from '@/core/enums/systemType';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { reportSelectors } from '@/core/redux/slices/smokeExtraction/system/report/selectors';
import { reportActions } from '@/core/redux/slices/smokeExtraction/system/report/slice';
import { systemSelectors } from '@/core/redux/slices/smokeExtraction/system/selectors';
import { systemActions } from '@/core/redux/slices/smokeExtraction/system/slice';

import { useCurrentCalculation } from '../hooks/useCurrentCalculation';

import { AerodynamicsView } from './views/AerodynamicsView/AerodynamicsView';
import { AirCurtainsView } from './views/AirCurtainsView/AirCurtainsView';
import { AirlockVestibulesView } from './views/AirlockVestibulesView/AirlockVestibulesView';
import { CompensatingAirSupplyView } from './views/CompensatingAirSupplyView/CompensatingAirSupplyView';
import { ElevatorShaftsView } from './views/ElevatorShaftsView/ElevatorShaftsView';
import { FacadeSmokeHatchesView } from './views/FacadeSmokeHatchesView/FacadeSmokeHatchesView';
import { FireSafeZonesView } from './views/FireSafeZonesView/FireSafeZonesView';
import { RemoveFromAdjacentToBurningView } from './views/RemoveFromAdjacentToBurningView/RemoveFromAdjacentToBurningView';
import { RemoveFromBurningView } from './views/RemoveFromBurningView/RemoveFromBurningView';
import { RoofSmokeHatchesView } from './views/RoofSmokeHatchesVIew/RoofSmokeHatchesView';
import { SmokeFreeStairwellsView } from './views/SmokeFreeStairwellsView/SmokeFreeStairwellsView';

const { Text, Title } = Typography;

export const SystemViewPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();

  const isReportReady = useAppSelector(systemSelectors.isReportReady);

  const [currentView, setCurrentView] = useState<SystemType>();
  const [isEditView, setIsEditView] = useState<boolean>(false);
  const [isSameHash, setIsSameHash] = useState<boolean>(false);
  const [editValue, setEditValue] = useState<string>('');

  const system = useAppSelector(systemSelectors.system);
  const reportStatus = useAppSelector(reportSelectors.status);
  const report = useAppSelector(reportSelectors.report);

  const currentCalculation = useCurrentCalculation({
    calculationID: Number(params.calculationId),
  });

  const reportHash = useMemo(() => report?.sec_state_hash, [report]);
  const isReportLoading = useMemo(
    () => reportStatus === LoadingStatus.LOADING,
    [reportStatus]
  );

  const routerParams = useParams();

  const systemID = Number(routerParams.systemId);

  const getSystemTypeFromString = (input: string) =>
    Object.values(SystemType).find((value) => value === input);

  useEffect(() => {
    dispatch(
      systemActions.fetchSystem({
        id: systemID,
      })
    );
    dispatch(
      reportActions.fetchReport({
        id: systemID,
      })
    );
  }, []);

  useEffect(() => {
    if (!system) {
      return;
    }

    const view = getSystemTypeFromString(system.type);

    setCurrentView(view);
  }, [system]);

  useEffect(() => {
    return () => {
      dispatch(systemActions.setSystem(null));
      dispatch(reportActions.setDownloadLink(null));
      dispatch(reportActions.setReportStatus(LoadingStatus.NEVER));
    };
  }, []);

  const handleCreateReport = (id: number, extension: string) => {
    if (isSameHash) {
      dispatch(
        reportActions.downloadReport({
          id: id,
          extension: extension,
        })
      );
    } else {
      dispatch(
        reportActions.createReport({
          id: id,
          extension: extension,
        })
      );
    }
  };

  const systemState = useAppSelector(systemSelectors.systemState);

  useEffect(() => {
    if (!systemState) {
      return;
    }

    const sortedKeys = Object.keys(systemState).sort();
    const sortedJsonStr = JSON.stringify(systemState, sortedKeys);
    const hashHex = MD5(sortedJsonStr).toString();

    setIsSameHash(hashHex === reportHash);
  }, [systemState, reportHash]);

  const systemViews: Record<SystemType, React.ReactNode> = {
    [SystemType.FacadeSmokeHatches]: (
      <FacadeSmokeHatchesView systemID={systemID} />
    ),
    [SystemType.RoofSmokeHatches]: <RoofSmokeHatchesView systemID={systemID} />,
    [SystemType.AirCompensation]: (
      <CompensatingAirSupplyView systemID={systemID} />
    ),
    [SystemType.AirCurtains]: <AirCurtainsView systemID={systemID} />,
    [SystemType.AirlockVestibules]: (
      <AirlockVestibulesView systemID={systemID} />
    ),
    [SystemType.FireSafeZones]: <FireSafeZonesView systemID={systemID} />,
    [SystemType.SmokeFreeStairwells]: (
      <SmokeFreeStairwellsView systemID={systemID} />
    ),
    [SystemType.RemovingFromBurning]: (
      <RemoveFromBurningView systemID={systemID} />
    ),
    [SystemType.RemoveFromAdjacentToBurningView]: (
      <RemoveFromAdjacentToBurningView systemID={systemID} />
    ),
    [SystemType.ElevatorShaftsView]: <ElevatorShaftsView systemID={systemID} />,
    [SystemType.AerodynamicsView]: <AerodynamicsView systemID={systemID} />,
  };

  const onDownloadOptionClick: MenuProps['onClick'] = ({ key }) => {
    handleCreateReport(systemID, key);
  };

  const downloadOptions: MenuProps['items'] = [
    {
      label: 'Выгрузить в формате doсx',
      key: 'docx',
    },
    {
      label: 'Выгрузить в формате pdf',
      key: 'pdf',
    },
  ];
  return (
    <div>
      <Breadcrumbs
        items={[
          {
            key: 'home',
            title: 'Все расчёты',
          },
          {
            key: 'objects',
            title: <Link to={'/objects'}>Объекты строительства</Link>,
          },
          ...(currentCalculation
            ? [
                {
                  key: 'currentObject',
                  title: (
                    <Link to={`/objects/${params.id}`}>
                      {currentCalculation.object_name}
                    </Link>
                  ),
                },
                {
                  key: 'system',
                  title: (
                    <Link to={`/objects/${params.id}/${params.calculationId}`}>
                      {currentCalculation.calc_name}
                    </Link>
                  ),
                },
                {
                  key: 'currentSystem',
                  title: system?.name,
                },
              ]
            : []),
        ]}
      />
      <ContentWrapper>
        <div>
          <div className='px-8 py-4'>
            <div className='flex justify-between'>
              <div className='flex gap-4 items-center'>
                <Button
                  icon={<LeftOutlined />}
                  onClick={() =>
                    navigate(
                      `/objects/${routerParams.id}/${routerParams.calculationId}`
                    )
                  }
                ></Button>
                {system && (
                  <div className='flex flex-col'>
                    <div className='flex gap-2 items-center'>
                      {isEditView ? (
                        <>
                          <Input
                            defaultValue={system.name}
                            onChange={(e) =>
                              setEditValue(e.currentTarget.value)
                            }
                            value={editValue || system.name}
                          />
                          <CheckOutlined
                            className='text-primary cursor-pointer'
                            onClick={() => {
                              setIsEditView(false);
                              dispatch(
                                systemActions.updateSystem({
                                  systemID: systemID,
                                  name: editValue,
                                })
                              );
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <Title level={5} className='!m-0'>
                            {editValue || system.name}
                          </Title>
                          <EditOutlined
                            className='text-primary cursor-pointer'
                            onClick={() => {
                              setIsEditView(true);
                            }}
                          />
                        </>
                      )}
                    </div>

                    <div className='flex gap-2'>
                      <Text>{system.type}</Text>{' '}
                      <Text type='secondary'>Сохраняется автоматически...</Text>
                    </div>
                  </div>
                )}
              </div>

              <TourItem
                className='w-[292px]'
                content='После завершения расчета нажмите "Сформировать отчет", чтобы создать печатную форму. Таким образом, вы получите отчет, готовый к печати и дальнейшему использованию.'
                step={9}
                placement='bottomRight'
              >
                {process.env.REACT_APP_ENABLE_PDF === 'enable' ? (
                  <Dropdown
                    menu={{
                      items: downloadOptions,
                      onClick: onDownloadOptionClick,
                    }}
                    trigger={['click']}
                  >
                    <Tooltip
                      placement='bottom'
                      title={isReportReady ? undefined : 'Заполните все поля'}
                    >
                      <Button
                        loading={isReportLoading}
                        type='primary'
                        icon={<DownOutlined />}
                        iconPosition='end'
                        disabled={!isReportReady}
                      >
                        Сформировать отчет
                      </Button>
                    </Tooltip>
                  </Dropdown>
                ) : (
                  <Tooltip
                    placement='bottom'
                    title={isReportReady ? undefined : 'Заполните все поля'}
                  >
                    <Button
                      loading={isReportLoading}
                      type='primary'
                      disabled={!isReportReady}
                      onClick={() => handleCreateReport(systemID, 'docx')}
                    >
                      Сформировать отчет
                    </Button>
                  </Tooltip>
                )}
              </TourItem>
            </div>
          </div>
          <div className='overflow-auto h-[calc(100vh_-_285px)]'>
            <div className='px-8 py-4 '>
              {currentView && systemViews[currentView]}
            </div>
          </div>
        </div>
      </ContentWrapper>
    </div>
  );
};
