import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/core/enums/loadingStatus';

export interface IAuthState {
  accessToken: string | null;
  registerLock: LoadingStatus;
  registerConfirmLock: LoadingStatus;
  loginLock: LoadingStatus;
  logoutLock: LoadingStatus;
  forgotPasswordLock: LoadingStatus;
  forgotPasswordConfirmLock: LoadingStatus;
}

export interface IRegisterPayload {
  email: string;
  password: string;
}

export interface IRegisterConfirmPayload {
  email: string;
  code: string;
}

export interface IForgotPasswordPayload {
  email: string;
}

export interface IForgotPasswordConfirmPayload {
  email: string;
  code: string;
  password: string;
}

export interface ILoginPayload {
  email: string;
  password: string;
}

export interface IChangePasswordPayload {
  currentPassword: string;
  newPassword: string;
}

const initialState: IAuthState = {
  accessToken: null,
  //register
  registerLock: LoadingStatus.NEVER,
  registerConfirmLock: LoadingStatus.NEVER,
  //login
  loginLock: LoadingStatus.NEVER,
  //logout
  logoutLock: LoadingStatus.NEVER,
  //forgot
  forgotPasswordLock: LoadingStatus.NEVER,
  forgotPasswordConfirmLock: LoadingStatus.NEVER
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    register: (state, action: PayloadAction<IRegisterPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    registerConfirm: (state, action: PayloadAction<IRegisterConfirmPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    login: (state, action: PayloadAction<ILoginPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    changePassword: (state, action: PayloadAction<IChangePasswordPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    logout: () => {},
    //forgot
    forgotPassword: (state, action: PayloadAction<IForgotPasswordPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    forgotPasswordConfirm: (state, action: PayloadAction<IForgotPasswordConfirmPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    //accessToken
    setAccessToken: (state, action: PayloadAction<string | null>) => ({
      ...state,
      accessToken: action.payload,
    }),
    //register statis
    setRegisterLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      registerLock: action.payload,
    }),
    setRegisterConfirmLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      registerConfirmLock: action.payload,
    }),
    // forgot password status
    setForgotPasswordLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      forgotPasswordLock: action.payload,
    }),
    setForgotPasswordConfirmLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      forgotPasswordConfirmLock: action.payload,
    }),
  },
});

export const authReducer = authSlice.reducer;
export const authActions = authSlice.actions;
