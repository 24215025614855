import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.system.params;

const cityParams = createSelector([selector], (state) => state.cityParams);
const cityParamsLock = createSelector(
  [selector],
  (state) => state.cityParamsLock
);

const systemTypes = createSelector([selector], (state) => state.systemTypes);
const systemTypesLock = createSelector(
  [selector],
  (state) => state.systemTypesLock
);

export const systemParamsSelectors = {
  cityParams,
  cityParamsLock,
  systemTypes,
  systemTypesLock,
};
