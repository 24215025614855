import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { LoadingStatus } from '@/core/enums/loadingStatus';
import type oas from '@/services/rest/base/openapi';

import {
  buildingObjectActions,
  IBuildingObjectAddPayload,
  IBuildingObjectCopyPayload,
  IBuildingObjectDeletePayload,
  IBuildingObjectFetchPayload,
  IBuildingObjectResponse,
  IBuildingObjectUpdatePayload,
  ICurrentBuildingObjectFetchPayload,
} from './slice';

type BuildingObjectResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/building_object',
  'get',
  '200'
>;

type CurrentBuildingObjectResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/building_object/{id}',
  'get',
  '200'
>;

type BuildingObjectCreateRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/building_object',
  'post'
>;

type BuildingObjectUpdateRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/building_object/{id}',
  'put'
>;

type BuildingObjectDeleteRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/building_object/{id}',
  'delete'
>;

function* fetchBuildingObject(
  action: PayloadAction<IBuildingObjectFetchPayload | undefined | null>
): Generator<any, void, BuildingObjectResponse> {
  const { payload } = action;

  yield put(buildingObjectActions.setBuildingObjectLock(LoadingStatus.LOADING));

  try {
    const response = yield call(
      restCall,
      '/smoke_extraction/building_object',
      'get',
      {
        query: {
          created_at__gte: payload?.['created_at__gte'],
          created_at__lte: payload?.['created_at__lte'],
          order_by: payload?.['order_by'],
          search: payload?.['search'],
          page: payload?.['page'],
          size: payload?.['size'],
        },
        ...authAdd(),
      }
    );

    const buildingObject: IBuildingObjectResponse = response;

    yield put(buildingObjectActions.setBuildingObject(buildingObject));

    yield put(
      buildingObjectActions.setBuildingObjectLock(LoadingStatus.LOADED)
    );
  } catch (error) {
    console.log('Error on build object fetching');
    yield put(buildingObjectActions.setBuildingObjectLock(LoadingStatus.ERROR));
  }
}

function* fetchCurrentBuildingObject(
  action: PayloadAction<ICurrentBuildingObjectFetchPayload>
): Generator<any, void, CurrentBuildingObjectResponse> {
  const { payload } = action;

  try {
    const response = yield call(
      restCall,
      '/smoke_extraction/building_object/{id}',
      'get',
      {
        params: {
          id: payload.id,
        },
        ...authAdd(),
      }
    );

    const currentBuildingObject: CurrentBuildingObjectResponse = response;

    yield put(
      buildingObjectActions.setCurrentBuildingObject(currentBuildingObject)
    );
  } catch (error) {
    console.log('Error on build object fetching');
  }
}

function* addBuildingObject(
  action: PayloadAction<IBuildingObjectAddPayload>
): Generator<any, void, any> {
  const { name, address, comment } = action.payload;

  yield put(
    buildingObjectActions.setAddBuildingObjectLock({
      status: LoadingStatus.LOADING,
      response: null,
    })
  );

  const request: BuildingObjectCreateRequest = {
    json: {
      name: name,
      address: address,
      comment: comment ?? null,
    },
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/smoke_extraction/building_object',
      'post',
      request
    );

    yield put(
      buildingObjectActions.setAddBuildingObjectLock({
        status: LoadingStatus.LOADED,
        response: response,
      })
    );
  } catch (error) {
    console.log('Error on create object', error);

    yield put(
      buildingObjectActions.setAddBuildingObjectLock({
        status: LoadingStatus.ERROR,
        response: null,
      })
    );
  }
}

function* updateBuildingObject(
  action: PayloadAction<IBuildingObjectUpdatePayload>
): Generator<any, void, any> {
  const { name, address, comment, buildingObjectID } = action.payload;

  const request: BuildingObjectUpdateRequest = {
    params: {
      id: buildingObjectID,
    },
    json: {
      name: name,
      address: address,
      comment: comment ?? null,
    },
    ...authAdd(),
  };

  try {
    yield call(
      restCall,
      '/smoke_extraction/building_object/{id}',
      'put',
      request
    );
  } catch (error) {
    console.log('Error on update object', error);
  }
}

function* deleteBuildingObject(
  action: PayloadAction<IBuildingObjectDeletePayload>
): Generator<any, void, any> {
  const { id } = action.payload;

  const request: BuildingObjectDeleteRequest = {
    params: {
      id: id,
    },
    ...authAdd(),
  };

  try {
    yield call(
      restCall,
      '/smoke_extraction/building_object/{id}',
      'delete',
      request
    );

    yield put(buildingObjectActions.fetchBuildingObject());
  } catch (error) {
    console.log('Error on delete object', error);
  }
}

function* copyBuildingObject(
  action: PayloadAction<IBuildingObjectCopyPayload>
): Generator<any, void, any> {
  const { id } = action.payload;

  const request: BuildingObjectDeleteRequest = {
    params: {
      id: id,
    },
    ...authAdd(),
  };

  try {
    yield call(
      restCall,
      '/smoke_extraction/building_object/{id}/copy',
      'post',
      request
    );

    yield put(buildingObjectActions.fetchBuildingObject());
  } catch (error) {
    console.log('Error on copy object', error);
  }
}

export const buildingObjectSagas = [
  takeLatest(buildingObjectActions.fetchBuildingObject, fetchBuildingObject),
  takeLatest(buildingObjectActions.addBuildingObject, addBuildingObject),
  takeLatest(buildingObjectActions.updateBuildingObject, updateBuildingObject),
  takeLatest(buildingObjectActions.deleteBuildingObject, deleteBuildingObject),
  takeLatest(buildingObjectActions.copyBuildingObject, copyBuildingObject),
  takeLatest(
    buildingObjectActions.fetchCurrentBuildingObject,
    fetchCurrentBuildingObject
  ),
];
