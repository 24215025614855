import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  BookOutlined,
  CompassOutlined,
  HomeOutlined,
  InboxOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Button, Menu, MenuProps, Typography } from 'antd';

import { TourItem } from '../TourItem/TourItem';

import styles from './styles.module.css';

type MenuItem = Required<MenuProps>['items'][number];

export const Sidebar: React.FC = () => {
  const location = useLocation();
  const [current, setCurrent] = useState(location.pathname);

  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname);
      }
    }
  }, [location]);

  const handleClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key);
  };

  const items: MenuItem[] = [
    {
      key: '/',
      label: (
        <Link
          to={{
            pathname: `/`,
          }}
        >
          <Typography.Text>Главная</Typography.Text>
        </Link>
      ),
      icon: <HomeOutlined />,
    },
    {
      key: 'calculations',
      label: 'Все расчёты',
      icon: <InboxOutlined />,
      children: [
        {
          key: '/objects',
          className: styles.breakable,
          label: (
            <TourItem
              className='w-[300px]'
              align={{ offset: [50, 0] }}
              content={
                'Раздел "Расчеты аэродинамики и систем ПДВ" - ваше основное рабочее пространство. Начните с выбора этого раздела и следуйте этапам инструкции.'
              }
              placement='rightTop'
              step={1}
              open={!location.pathname.includes('/objects')}
            >
              <Link
                to={{
                  pathname: `objects`,
                }}
              >
                <Typography.Text>
                  Расчеты аэродинамики <br /> и систем ПДВ
                </Typography.Text>
              </Link>
            </TourItem>
          ),
        },
      ],
    },
    {
      key: '/tools',
      label: (
        <Link
          to={{
            pathname: `/tools`,
          }}
        >
          <Typography.Text>Подбор оборудования</Typography.Text>
        </Link>
      ),
      icon: <CompassOutlined />,
    },
    {
      key: 'info',
      label: 'База знаний',
      icon: <BookOutlined />,
      children: [
        {
          key: '/documentation',
          label: (
            <Link
              to={{
                pathname: `/documentation`,
              }}
            >
              <Typography.Text>Документация</Typography.Text>
            </Link>
          ),
        },
        {
          key: '/air-exchange',
          label: (
            <Link
              to={{
                pathname: `/air-exchange`,
              }}
            >
              <Typography.Text>Воздухообмен</Typography.Text>
            </Link>
          ),
        },
      ],
    },
    {
      key: '/profile',
      label: (
        <Link
          to={{
            pathname: `profile`,
          }}
        >
          <Typography.Text>Личный кабинет</Typography.Text>
        </Link>
      ),
      icon: <UserOutlined />
    },
  ];

  return (
    <div className='z-10 h-full bg-white shadow-[0px_2px_8px_0px_rgba(0,0,0,0.15)] flex flex-col justify-between'>
      <Menu
        onClick={handleClick}
        style={{ width: 256 }}
        selectedKeys={[current]}
        mode='inline'
        items={items}
        defaultOpenKeys={['calculations', 'personal', 'info']}
      />

      <div className='p-4 gap-2 flex flex-col'>
        <Button
          type='primary'
          onClick={() => window.open('https://t.me/AeroPro_Expert_Bot')}
        >
          Чат с экспертом
        </Button>
        <Button
          type='link'
          onClick={() =>
            window.open(
              'https://forms.yandex.ru/cloud/66895defc417f32b335c12d2/'
            )
          }
        >
          Предложить идею
        </Button>
        <Button
          type='link'
          onClick={() =>
            window.open(
              'https://forms.yandex.ru/cloud/668ba1882530c256d7ff05c5/'
            )
          }
        >
          Обратная связь
        </Button>
      </div>
    </div>
  );
};
