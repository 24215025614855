import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Typography } from 'antd';
import * as yup from 'yup';

import { InputForm } from '@/components/form/Input';
import { PasswordForm } from '@/components/form/Password';
import { LoadingStatus } from '@/core/enums/loadingStatus';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { authSelectors } from '@/core/redux/slices/auth/selectors';
import { authActions } from '@/core/redux/slices/auth/slice';
import { usersSelectors } from '@/core/redux/slices/users/selectors';

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Неправильный формат почты')
    .required('Поле обязательно для заполнения'),
  password: yup.string().required('Поле обязательно для заполнения'),
  passwordConfirmation: yup
    .string()
    .required('Поле обязательно для заполнения')
    .oneOf([yup.ref('password')], 'Пароли должны совпадать'),
});

interface ISignUpForm {
  email: string;
  password: string;
  passwordConfirmation: string;
}

export const SignUpPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const registerLock = useAppSelector(authSelectors.registerLock);
  const user = useAppSelector(usersSelectors.user);

  const methods = useForm<ISignUpForm>({
    resolver: yupResolver(schema),
  });
  const { Text } = Typography;

  const onSubmit = (data: ISignUpForm) => {
    dispatch(
      authActions.register({
        email: data.email,
        password: data.password,
      })
    );
  };

  useEffect(() => {
    if (registerLock === LoadingStatus.LOADED) {
      navigate('/auth/confirm');
    }
  }, [registerLock]);

  useEffect(() => {
    return () => {
      dispatch(authActions.setRegisterLock(LoadingStatus.NEVER));
    };
  }, []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className='flex flex-col gap-6'>
          <div className='flex flex-col gap-2'>
            <InputForm name='email' placeholder='Ваш e-mail' size='large' />
            <PasswordForm
              name='password'
              placeholder='Придумайте пароль'
              size='large'
            />
            <PasswordForm
              name='passwordConfirmation'
              placeholder='Повторите пароль'
              size='large'
            />
          </div>
          <div>
            <Button
              htmlType='submit'
              type='primary'
              block
              className='mb-2'
              loading={registerLock === LoadingStatus.LOADING}
            >
              Получить код подтверждения
            </Button>
            <div className='text-center'>
              <Text type='secondary' className='text-xs'>
                Нажимая на кнопку, вы принимаете условия{' '}
                <Link
                  to='/docs/Пользовательское_соглашение_Aeropro_Expert.pdf'
                  target='_blank'
                  rel='noreferrer'
                >
                  <Text type='secondary' underline className='text-xs'>
                    пользовательского соглашения
                  </Text>
                </Link>
              </Text>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
