import { Typography } from 'antd';

import { InputNumberForm } from '@/components/form/InputNumber';

interface IFixedHeatDissipation {
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const { Text, Title } = Typography;

export const FixedHeatDissipation: React.FC<IFixedHeatDissipation> = ({
  handleBlur,
}) => {
  return (
    <div>
      <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
        <InputNumberForm
          name='Qf'
          addonBefore={
            <i>
              Q<sub>f</sub> =
            </i>
          }
          addonAfter={<i>кВт</i>}
          label='Фиксированная мощность тепловыделения очага пожара'
          fixValue={0}
          onBlur={handleBlur}
          size='large'
        />
      </div>
    </div>
  );
};
