import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.tour;

const stepIndex = createSelector([selector], (state) => state.stepIndex);
const isTourRuning = createSelector([selector], (state) => state.isTourRuning);


export const tourSelectors = { stepIndex, isTourRuning };
