import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/core/enums/loadingStatus';
import { ReportStatus } from '@/core/enums/reportStatus';
import { SystemType } from '@/core/enums/systemType';

export interface IReportState {
  report: IReport | null;
  status: LoadingStatus;
  downloadLink: string | null;
}

export interface IFetchReportPayload {
  id: number;
}
export interface ICreateReportPayload {
  id: number;
  extension: string;
}

export interface IRequestReportPayload {
  id: number;
  extension: string;
}

export interface IDownloadReportPayload {
  id: number;
  extension: string;
}

export interface IReport {
  status: string;
  id: number;
  sec_id: number;
  sec_state_hash: string | null;
  name: string;
  created_at: string;
  updated_at: string;
}

const initialState: IReportState = {
  report: null,
  status: LoadingStatus.NEVER,
  downloadLink: null,
};

const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    createReport: (state, action: PayloadAction<ICreateReportPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    fetchReport: (state, action: PayloadAction<IFetchReportPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    requestReport: (state, action: PayloadAction<IRequestReportPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    downloadReport: (state, action: PayloadAction<IDownloadReportPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setReportStatus: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      status: action.payload,
    }),
    setDownloadLink: (state, action: PayloadAction<string | null>) => ({
      ...state,
      downloadLink: action.payload,
    }),
    setReport: (state, action: PayloadAction<IReport | null>) => ({
      ...state,
      report: action.payload,
    }),
  },
});

export const reportReducer = reportSlice.reducer;
export const reportActions = reportSlice.actions;
