import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { all, ForkEffect, put } from 'redux-saga/effects';

import { LoadingStatus } from '@/core/enums/loadingStatus';
import { ActionCreator } from '@/core/redux/utils/actions';

export const sagaMiddleware = createSagaMiddleware();

export const createRootSaga = (sagas: ForkEffect<never>[] = []) => {
  return function* main() {
    try {
      yield all(sagas);
    } catch (e) {
      console.warn(e);
    }
  };
};

//mixins

export const mixinWithLoading =
  (action: ActionCreator | ActionCreatorWithPayload<any, string>) =>
  (next: Function) =>
    function* busyWrapper() {
      yield put(action(LoadingStatus.LOADING));
      yield next();
      yield put(action(LoadingStatus.LOADED));
    };

export const mixinWithOnlyStartLoading =
  (action: ActionCreator | ActionCreatorWithPayload<any, string>) =>
  (next: Function) =>
    function* busyWrapper() {
      yield put(action(LoadingStatus.LOADING));
      yield next();
    };

export const mixinWithErrorHandler =
  (
    key: string,
    action: ActionCreator | ActionCreatorWithPayload<any, string>
  ) =>
  (next: Function) =>
    function* errorHandlerWrapper() {
      try {
        yield next();
      } catch (error: any) {
        console.log(error.message);
        yield put(action(LoadingStatus.ERROR));
      }
    };

//augment saga

const mixinWithAugment = (next: Function) =>
  function* busyWrapper() {
    yield next();
  };

export const augmentSaga = (
  saga: (...args: any) => any,
  ...wrapperSagas: ((...args: any) => any)[]
) =>
  [mixinWithAugment, ...wrapperSagas].reduceRight(
    (saga, wrapperSaga) =>
      function* aurmenter(...args) {
        yield wrapperSaga(() => saga(...args))(...args);
      },
    saga
  );
