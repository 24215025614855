// DynamicTable.tsx

import React, { useState } from 'react';
import {
  Controller,
  FieldValues,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { Button, Input, Table, TableColumnsType, Typography } from 'antd';

import { InputNumberForm } from '@/components/form/InputNumber';
import { useAppSelector } from '@/core/redux/hooks';
import { systemSelectors } from '@/core/redux/slices/smokeExtraction/system/selectors';
import { AddIcon } from '@/icons/AddIcon';
import { DeleteIcon } from '@/icons/DeleteIcon';

interface DataType {
  key: string;
  Mi: number;
  mi: number;
  Qp_ni: number;
}

const { Text, Title } = Typography;

interface IDynamicTable {
  handleChange: () => void;
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const DynamicTable: React.FC<IDynamicTable> = ({
  handleChange,
  handleBlur,
}) => {
  const { control, register } = useFormContext();
  const systemState = useAppSelector(systemSelectors.systemState);

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: 'fire_load',
    }
  );

  const handleAddRow = () => {
    append({ Mi: 0, mi: 0, Qp_ni: 0 });
    handleChange();
  };

  const handleRemoveRow = (index: number) => {
    remove(index);
    handleChange();
  };

  const columns: TableColumnsType<any> = [
    {
      title: 'i',
      dataIndex: 'i',
      render: (text: string, record: any, index: number) => (
        <Text>{index + 1}</Text>
      ),
    },
    {
      title: (
        <i>
          M<sub>i</sub> (кг)
        </i>
      ),
      dataIndex: 'Mi',
      render: (text: string, record: any, index: number) => (
        <InputNumberForm name={`fire_load[${index}].Mi`} onBlur={handleBlur} />
      ),
    },
    {
      title: (
        <i>
          m<sub>i</sub> (кг)
        </i>
      ),
      dataIndex: 'mi',
      render: (text: string, record: any, index: number) =>
        Number(text).toFixed(3),
    },
    {
      title: (
        <i>
          Q<sup>p</sup>
          <sub>Hi</sub>  (МДж/кг)
        </i>
      ),
      dataIndex: 'Qp_ni',
      render: (text: string, record: any, index: number) => (
        <InputNumberForm
          name={`fire_load[${index}].Qp_ni`}
          onBlur={handleBlur}
        />
      ),
    },
    {
      title: '',
      render: (_: any, record: any, index: number) => (
        <div className='flex gap-2'>
          <Button onClick={handleAddRow} className='py-1 px-2.5'>
            <AddIcon />
          </Button>
          <Button
            onClick={() => handleRemoveRow(index)}
            className='py-1 px-2.5'
          >
            <DeleteIcon />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table
        dataSource={fields}
        columns={columns}
        pagination={false}
        rowKey='id'
        summary={(pageData) => {

          console.log('systemState', systemState)

          let MiSummary = 0;
          let miSummary = 0;
          const QpNiSummary = systemState ? Number(systemState['Qpns']) : 0;

          pageData.forEach(({ Mi, mi }) => {
            MiSummary += Number(Mi);
            miSummary += Number(mi);
          });

          return (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Итого</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>{MiSummary}</Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  {miSummary.toFixed(3)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                  {QpNiSummary.toFixed(1)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={5}>
                  <Text strong>
                    M<sub>i</sub>{' '}
                  </Text>
                  <Text>
                    – масса i-го вещества или материала в составе пожарной
                    нагрузки
                  </Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={5}>
                  <Text strong>
                    m<sub>i</sub>{' '}
                  </Text>
                  <Text>
                    – относительная массовая доля i-го вещества или материала в
                    составе пожарной нагрузки
                  </Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>

              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={5}>
                  <Text strong>
                    Q<sup>p</sup>
                    <sub>Hi</sub> (МДж/кг){' '}
                  </Text>
                  <Text>
                    – теплота сгорания i-го вещества или материала в составе
                    пожарной нагрузки
                  </Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />
    </div>
  );
};

export default DynamicTable;
