import { FocusEvent, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { SelectProps, Typography } from 'antd';

import { InputNumberForm } from '@/components/form/InputNumber';
import { DebounceSelect } from '@/components/ui/DebounceSelect/DebounceSelect';
import { LoadingStatus } from '@/core/enums/loadingStatus';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { systemCalculationsActions } from '@/core/redux/slices/smokeExtraction/system/calculations/slice';
import { systemParamsSelectors } from '@/core/redux/slices/smokeExtraction/system/params/selectors';
import { systemParamsActions } from '@/core/redux/slices/smokeExtraction/system/params/slice';
import { systemSelectors } from '@/core/redux/slices/smokeExtraction/system/selectors';

const { Title } = Typography;

export interface IRoofSmokeHatchesView {
  systemID: number;
}

export const RoofSmokeHatchesView: React.FC<IRoofSmokeHatchesView> = ({
  systemID,
}) => {
  const cityParams = useAppSelector(systemParamsSelectors.cityParams);
  const cityParamsLock = useAppSelector(systemParamsSelectors.cityParamsLock);

  const systemState = useAppSelector(systemSelectors.systemState);

  const dispatch = useAppDispatch();

  const methods = useForm<any>({
    defaultValues: {
      city_id: 1,
      city: 'Москва',
      ta: 0,
      va: 0,
      pa: null,
      Gsm: null,
      hsm: null,
      tsm: null,
      Scsh: null,
      Ed: null,
      kaww: 0.8,
      kaw0: -0.6,
      Tsm: null,
      psm: null,
      Fsm: null,
      N: null,
    },
  });

  const cityParamsOptions: SelectProps['options'] = useMemo(() => {
    if (!cityParams) {
      return [];
    }

    return cityParams.map((item) => ({
      value: item.id,
      label: item.city,
    }));
  }, [cityParams]);

  const handlePerformCalculation = () => {
    const data = methods.getValues();

    dispatch(
      systemCalculationsActions.performRoofSmokeHatches({
        calc_id: systemID,
        data: data,
      })
    );
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (!systemState || systemState[name] !== value) {
      handlePerformCalculation();
    }
  };

  const onSelectedCityChange = (id: string) => {
    if (!cityParams) {
      return;
    }

    const selectedCityID = Number(id);

    const selectedCity = cityParams.find((item) => item.id === selectedCityID);

    if (selectedCity) {
      methods.setValue('city', String(selectedCity.city));
      methods.setValue('ta', Number(selectedCity.warm_temp));
      methods.setValue('va', Number(selectedCity.max_speed));

      handlePerformCalculation();
    }
  };

  useEffect(() => {
    systemState && methods.reset(systemState);
  }, [systemState]);

  useEffect(() => {
    if (!systemState) {
      dispatch(
        systemParamsActions.fetchCityParams({
          page: 1,
          size: 10,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (cityParamsLock === LoadingStatus.LOADED) {
      !systemState && cityParams && onSelectedCityChange('1');
    }
  }, [cityParamsLock]);

  return (
    <FormProvider {...methods}>
      <form>
        <div className='py-4 px-2'>
          <Title level={5} className='mb-4'>
            Город
          </Title>
          <DebounceSelect
            size='large'
            showSearch
            placeholder='Выберите город'
            defaultValue={{
              value: methods.getValues('city_id'),
              label: methods.getValues('city'),
            }}
            value={{
              value: methods.getValues('city_id'),
              label: methods.getValues('city'),
            }}
            style={{ width: '100%' }}
            onChange={(newValue: any) => {
              methods.setValue('city_id', newValue.key);
              onSelectedCityChange(newValue.key);
            }}
            options={cityParamsOptions}
            fetchOptions={(search: string) => {
              dispatch(
                systemParamsActions.fetchCityParams({
                  page: 1,
                  size: 10,
                  search: search,
                })
              );
            }}
            loading={cityParamsLock === LoadingStatus.LOADING}
          />
        </div>
        <div className='py-4 px-2'>
          <div className='grid grid-cols-3 gap-4 gap-y-8'>
            <InputNumberForm
              name='ta'
              addonBefore={
                <i>
                  t<sub>a</sub> =
                </i>
              }
              addonAfter={'°C'}
              label='Температура наружного воздуха'
              disabled
              onBlur={handleBlur}
              size='large'
            />
            <InputNumberForm
              name='pa'
              addonBefore={
                <i>
                  ρ<sub>a</sub> =
                </i>
              }
              addonAfter={
                <i>
                  кг/м<sup>3</sup>
                </i>
              }
              label='Плотность наружного воздуха'
              disabled
              onBlur={handleBlur}
              size='large'
            />
            <InputNumberForm
              addonBefore={
                <i>
                  v<sub>a</sub> =
                </i>
              }
              addonAfter={<i>м/с</i>}
              name='va'
              disabled
              label='Скорость ветра'
              onBlur={handleBlur}
              size='large'
            />
            <InputNumberForm
              addonBefore={
                <i>
                  k<sub>aww</sub> =
                </i>
              }
              name='kaww'
              label='Коэффициент ветрового напора для наветренного фасада'
              note='Согласно разделу 2 МД.137-13'
              placeholder='Введите значение'
              onBlur={handleBlur}
              size='large'
            />
            <InputNumberForm
              addonBefore={
                <i>
                  k<sub>aw0</sub> =
                </i>
              }
              name='kaw0'
              label='Коэффициент ветрового напора для заветренного фасада'
              note='Согласно разделу 2 МД.137-13'
              placeholder='Введите значение'
              onBlur={handleBlur}
              size='large'
            />
            <InputNumberForm
              addonBefore={
                <i>
                  k<sub>aws</sub> =
                </i>
              }
              name='kaws'
              label='Коэффициент ветрового напора для боковой стороны'
              note='Согласно разделу 2 МД.137-13'
              placeholder='Введите значение'
              onBlur={handleBlur}
              size='large'
            />
          </div>
        </div>

        <div className='py-4 px-2'>
          <Title level={5} className='mb-4'>
            Параметры дыма
          </Title>
          <div className='grid grid-cols-3 gap-4 gap-y-8'>
            <InputNumberForm
              addonBefore={
                <i>
                  t<sub>sm</sub> =
                </i>
              }
              addonAfter='°C'
              name='tsm'
              label='Температура дымового слоя'
              placeholder='Введите значение'
              onBlur={handleBlur}
              size='large'
            />
            <InputNumberForm
              addonBefore={
                <i>
                  G<sub>sm</sub> =
                </i>
              }
              addonAfter='кг/с'
              fixValue={2}
              name='Gsm'
              label='Массовый расход удаляемых продуктов горения'
              placeholder='Введите значение'
              onBlur={handleBlur}
              size='large'
            />
            <InputNumberForm
              addonBefore={
                <i>
                  h<sub>sm</sub> =
                </i>
              }
              addonAfter={<i>м</i>}
              name='hsm'
              label='Толщина дымового слоя '
              placeholder='Введите значение'
              onBlur={handleBlur}
              size='large'
            />
            <InputNumberForm
              addonBefore={
                <i>
                  T<sub>sm</sub> =
                </i>
              }
              addonAfter={<i>°К</i>}
              name='Tsm'
              label='Температура дыма'
              disabled
              onBlur={handleBlur}
              size='large'
            />
            <InputNumberForm
              addonBefore={
                <i>
                  ρ<sub>sm</sub> =
                </i>
              }
              addonAfter={
                <i>
                  кг/м<sup>3</sup>
                </i>
              }
              name='psm'
              label='Плотность дымового слоя'
              disabled
              onBlur={handleBlur}
              size='large'
            />
          </div>
        </div>
        <div className='py-4 px-2'>
          <Title level={5} className='mb-4'>
            Характеристики дымовых люков
          </Title>
          <div className='grid grid-cols-2 gap-4 gap-y-8'>
            <InputNumberForm
              addonBefore={
                <i>
                  S<sub>ж.с.люка</sub> =
                </i>
              }
              addonAfter={
                <i>
                  м<sup>2</sup>
                </i>
              }
              name='Scsh'
              label='Живое сечение одного дымового люка, принятого к установке'
              placeholder='Введите значение'
              onBlur={handleBlur}
              size='large'
            />
            <InputNumberForm
              addonBefore={
                <i>
                  ξ<sub>d</sub> =
                </i>
              }
              name='Ed'
              label='Коэффициент местного сопротивления (КМС) дымового люка'
              placeholder='Введите значение'
              onBlur={handleBlur}
              size='large'
            />
            <InputNumberForm
              addonBefore={
                <i>
                  F<sub>sm</sub> =
                </i>
              }
              addonAfter={
                <i>
                  м<sup>2</sup>
                </i>
              }
              name='Fsm'
              label='Минимально необходимое суммарное проходное сечение дымовых люков'
              fixValue={2}
              disabled
              onBlur={handleBlur}
              size='large'
            />
            <InputNumberForm
              addonBefore={<i>N =</i>}
              addonAfter={<i>шт</i>}
              name='N'
              label='Количество дымовых люков'
              disabled
              onBlur={handleBlur}
              size='large'
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
